import * as React from 'react';
import {Link} from 'react-router-dom';

import './questions.scss';

interface State {
    title?: string;
}

interface Props {
    title?: string;
}

class Questions extends React.Component<Props, State> {

    state: State = {};

    init = (): void => {
        this.registerListerners();
    };

    registerListerners = (): void => {
        console.debug('Registrando listeners...');
    };

    componentDidMount = (): void => {
        this.init();
    };

    render = () => {
        return (
            <div className="ac-questions shadow-sm-up">
                <div className="row">
                    <div className="col">
                        <div className="container-lg pt-6 pb-6">
                            <div className="col-12 text-center">
                                <span className="ac-questions__faq-title">Tire suas principais dúvidas</span>
                            </div>
                            <div className="col-12 text-center mt-2 ac-questions__faq-links">
                                <Link className="br-button secondary" type="button" aria-label="Sobre o Sisu" to={"/sisu"}>
                                    Sobre o SISU
                                </Link>
                                <Link className="br-button secondary" type="button" aria-label="Sobre o Prouni" to={"/prouni"}>
                                    Sobre o PROUNI
                                </Link>
                                <Link className="br-button secondary" type="button" aria-label="Sobre o Fies" to={"/fies"}>
                                    Sobre o FIES
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };
}

export default Questions;
