import ResourceModel from './resource.model';
import ContentModel from './content.model';
import {CalendarModel} from './calendar.model';
import ProgramButtonModel from './program-button.model';

class ProgramModel {

    title: string;

    alias: string;

    calendar: Array<CalendarModel>;

    avatar: ResourceModel;

    videos: Array<ResourceModel>;

    download: ResourceModel;

    content: Array<ContentModel>;

    topics: Array<ContentModel>;

    buttons: Array<ProgramButtonModel>;

    bottomAlert: string;
}

export default ProgramModel;
