export const GA_PROD_TRACKING_ID = 'G-M8BDPMF48G';
export const GA_HOMOL_TRACKING_ID = 'G-JTE7KNX429';

const hostname: string = window.location.hostname;

let currentTrackingId = '';

if (/^.*acessounico.mec.gov.br.*/.test(hostname)) {
    currentTrackingId = GA_PROD_TRACKING_ID;
} else {
    currentTrackingId = GA_HOMOL_TRACKING_ID;
}

export const init = (): void => {
    const gtmScript = document.createElement('script');
    gtmScript.async = true;
    gtmScript.src = `https://www.googletagmanager.com/gtag/js?id=${currentTrackingId}`;
    document.head.appendChild(gtmScript);

    const gtagScript = document.createElement('script');
    gtagScript.innerHTML = `window.dataLayer = window.dataLayer || [];
                            function gtag(){
                                dataLayer.push(arguments);
                            }
                            window.gtag = gtag;
                            `;

    document.head.appendChild(gtagScript);

    window.gtag('js', new Date());

    window.gtag('config', currentTrackingId, {
        page_title : 'Portal Único de Acesso ao Ensino Superior',
        page_path: '/',
        send_page_view: false
    });
};

export const pageview = (title: string, path: string): void => {
    window.gtag('config', currentTrackingId, {
        page_title : title,
        page_path: path,
        send_page_view: true
    });
};
