import * as gtag from '../utils/gtag.util';

class GoogleAnalyticsService {

    static instance: GoogleAnalyticsService = null;

    static getInstance() {
        if (GoogleAnalyticsService.instance == null) {
            GoogleAnalyticsService.instance = new GoogleAnalyticsService();
        }

        return this.instance;
    }

    setPageView(title: string, path: string): void {
        gtag.pageview(title, path);
    }
}

export default GoogleAnalyticsService;
