import * as React from 'react';
import Contact from '../../components/contact/contact';
import {JourneyModel} from '../../models/journey.model';
import GoogleAnalyticsService from '../../services/google-analytics.service';
import {Link} from 'react-router-dom';

import './journey.scss';

interface State {
    title?: string;
    path?: string;
}

interface Props {
    path?: string;
}

class Journey extends React.Component<Props, State> {

    readonly data: JourneyModel = require('../../assets/mock/journey.json');

    readonly avatar = require('../../assets/img/journey_avatar_2.png');

    readonly avatarMobile = require('../../assets/img/journey_avatar_mobile.png');

    readonly avatarBottom = require('../../assets/img/journey_bottom_avatar.png');

    readonly avatarBottomMobile = require('../../assets/img/journey_bottom_avatar_mobile.png');

    readonly worm = require('../../assets/img/journey_worm.svg');

    readonly finalWorm = require('../../assets/img/journey_worm_final.svg');

    readonly firstStep = require('../../assets/img/journey_01_notebooks.png');

    readonly secondStep = require('../../assets/img/journey_02_enem.png');

    readonly thirdStep = require('../../assets/img/logo_sisu.svg');

    readonly fourthStep = require('../../assets/img/logo_prouni.svg');

    readonly fifithStep = require('../../assets/img/journey_05_light.png');

    readonly sixthStep = require('../../assets/img/logo_fies.svg');

    readonly seventhStep = require('../../assets/img/journey_07_docs.png');

    readonly eighthStep = require('../../assets/img/journey_08_backpack.png');

    state: State = {};

    googleAnalyticsService: GoogleAnalyticsService;

    init = (): void => {
        this.registerListerners();
    };

    registerListerners = (): void => {
        console.debug('Registrando listeners...');
    };

    registerGooglePageView = (): void => {
        this.googleAnalyticsService = GoogleAnalyticsService.getInstance();
        this.googleAnalyticsService.setPageView(this.data.title, window.location.pathname);
    };

    handleCLick = (uri: any): void => {
        window.location = uri;
    };

    componentDidMount = (): void => {
        this.init();
        this.registerGooglePageView();
    };

    componentDidUpdate = (prevProps: Props): void => {
        if (this.props.path !== prevProps.path) {
            this.init();
        }
    };

    render = () => {
        return (
            <div className="ac-journey main-content d-flex flex-column justify-content-between pt-2" id="main-content">
                <div className="container-lg d-flex flex-column flex-grow-1">
                    <div className="row">
                        <div className="col-12 col-sm-12 col-md-6">
                            <h1>
                                {this.data.title}
                            </h1>
                        </div>
                        <div className="col-12 col-sm-12 col-md-6 ac-journey__top-action">
                            <div className="br-magic-button">
                                <Link className="br-button primary" type="button"
                                      to={this.data.action.uri}>
                                    <span
                                        className="d-none d-sm-none d-md-none d-lg-block">{this.data.action.title}</span>
                                    <span
                                        className="d-block d-sm-block d-md-block d-lg-none">{this.data.action.titleShort}</span>
                                </Link>
                            </div>
                        </div>
                        <div className="col-12 ac-journey__content-container">
                            <div className="ac-journey__worm-container">
                                <div className="ac-journey__avatar-container">
                                    <div className="ac-journey__speech">
                                        <blockquote
                                            className="ac-oval-speech ac-oval-speech--rounded ac-oval-speech--pink ac-oval-speech--left ac-oval-speech--medium-alt-2 d-none d-sm-none d-md-block d-lg-block d-xl-block">
                                            <p>{this.data.balloon.text}</p>
                                        </blockquote>
                                        <blockquote
                                            className="ac-oval-speech ac-oval-speech--rounded ac-oval-speech--pink ac-oval-speech--bottom ac-oval-speech--medium-alt d-block d-sm-block d-md-none d-lg-none d-xl-none">
                                            <p>{this.data.balloon.textShort}</p>
                                        </blockquote>
                                    </div>
                                    <div
                                        className="ac-journey__avatar d-none d-sm-none d-md-block d-lg-block d-xl-block">
                                        <img src={this.avatar} alt="Avatar"/>
                                    </div>
                                    <div
                                        className="ac-journey__avatar-mobile d-block d-sm-block d-md-none d-lg-none d-xl-none">
                                        <img src={this.avatarMobile} alt="Avatar"/>
                                    </div>
                                </div>
                                <div className="ac-journey__worm d-none d-sm-none d-md-block d-lg-block d-xl-block">
                                    <img src={this.worm} alt="Jornada"/>

                                    <div className="ac-journey__step ac-journey__step--right">
                                        <img src={this.firstStep} alt="Passo"/>
                                        <div className="ac-journey__step-text">
                                            <div>
                                                <p dangerouslySetInnerHTML={{__html: this.data.steps[0].text}}></p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="ac-journey__step ac-journey__step--left">
                                        <img src={this.secondStep} alt="Passo"/>
                                        <div className="ac-journey__step-text">
                                            <div>
                                                <p dangerouslySetInnerHTML={{__html: this.data.steps[1].text}}></p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="ac-journey__step ac-journey__step--right">
                                        <img className="ac-contrast" src={this.thirdStep} alt="Passo"/>
                                        <div className="ac-journey__step-text">
                                            <div>
                                                <p dangerouslySetInnerHTML={{__html: this.data.steps[2].text}}></p>
                                                <Link className="br-button primary mt-3" type="button"
                                                      aria-label={this.data.steps[2].action.title}
                                                      to={this.data.steps[2].action.uri}>
                                                    {this.data.steps[2].action.title}
                                                </Link>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="ac-journey__step ac-journey__step--left">
                                        <img className="ac-contrast" src={this.fourthStep} alt="Passo"/>
                                        <div className="ac-journey__step-text">
                                            <div>
                                                <p dangerouslySetInnerHTML={{__html: this.data.steps[3].text}}></p>
                                                <Link className="br-button primary mt-3" type="button"
                                                      aria-label={this.data.steps[3].action.title}
                                                      to={this.data.steps[3].action.uri}>
                                                    {this.data.steps[3].action.title}
                                                </Link>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="ac-journey__step ac-journey__step--right">
                                        <img src={this.fifithStep} alt="Passo"/>
                                        <div className="ac-journey__step-text">
                                            <div>
                                                <p dangerouslySetInnerHTML={{__html: this.data.steps[4].text}}></p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="ac-journey__step ac-journey__step--left">
                                        <img className="ac-contrast" src={this.sixthStep} alt="Passo"/>
                                        <div className="ac-journey__step-text">
                                            <div>
                                                <p dangerouslySetInnerHTML={{__html: this.data.steps[5].text}}></p>
                                                <Link className="br-button primary mt-3" type="button"
                                                      aria-label={this.data.steps[5].action.title}
                                                      to={this.data.steps[5].action.uri}>
                                                    {this.data.steps[5].action.title}
                                                </Link>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="ac-journey__step ac-journey__step--right">
                                        <img src={this.seventhStep} alt="Passo"/>
                                        <div className="ac-journey__step-text">
                                            <div>
                                                <p dangerouslySetInnerHTML={{__html: this.data.steps[6].text}}></p>
                                                <Link className="br-button primary mt-3" type="button"
                                                      aria-label={this.data.steps[6].action.title}
                                                      to={this.data.steps[6].action.uri}>
                                                    {this.data.steps[6].action.title}
                                                </Link>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="ac-journey__step ac-journey__step--left">
                                        <img src={this.eighthStep} alt="Passo"/>
                                        <div className="ac-journey__step-text">
                                            <div>
                                                <p dangerouslySetInnerHTML={{__html: this.data.steps[7].text}}></p>
                                            </div>
                                        </div>
                                    </div>

                                    {/*<div className="ac-journey__final-container">*/}
                                    {/*    <img src={this.finalWorm} alt="Final da ilustração"/>*/}
                                    {/*</div>*/}
                                </div>
                            </div>
                            <div className="col-12 ac-journey__mobile-container">
                                <div className="ac-journey__mobile-card">
                                    <div className="ac-journey__mobile-image">
                                        <img src={this.firstStep} alt="Passo"/>
                                    </div>
                                    <div className="ac-journey__mobile-text">
                                        <p dangerouslySetInnerHTML={{__html: this.data.steps[0].text}}></p>
                                    </div>
                                </div>
                                <div className="ac-journey__mobile-card">
                                    <div className="ac-journey__mobile-image">
                                        <img src={this.secondStep} alt="Passo"/>
                                    </div>
                                    <div className="ac-journey__mobile-text">
                                        <p dangerouslySetInnerHTML={{__html: this.data.steps[1].text}}></p>
                                    </div>
                                </div>
                                <div className="ac-journey__mobile-card">
                                    <div className="ac-journey__mobile-image">
                                        <img src={this.thirdStep} alt="Passo"/>
                                    </div>
                                    <div className="ac-journey__mobile-text">
                                        <p dangerouslySetInnerHTML={{__html: this.data.steps[2].text}}></p>
                                        <Link className="br-button primary mt-3" type="button"
                                              aria-label={this.data.steps[2].action.title}
                                              to={this.data.steps[2].action.uri}>
                                            {this.data.steps[2].action.title}
                                        </Link>
                                    </div>
                                </div>
                                <div className="ac-journey__mobile-card">
                                    <div className="ac-journey__mobile-image">
                                        <img src={this.fourthStep} alt="Passo"/>
                                    </div>
                                    <div className="ac-journey__mobile-text">
                                        <p dangerouslySetInnerHTML={{__html: this.data.steps[3].text}}></p>
                                        <Link className="br-button primary mt-3" type="button"
                                              aria-label={this.data.steps[3].action.title}
                                              to={this.data.steps[3].action.uri}>
                                            {this.data.steps[3].action.title}
                                        </Link>
                                    </div>
                                </div>
                                <div className="ac-journey__mobile-card">
                                    <div className="ac-journey__mobile-image">
                                        <img src={this.fifithStep} alt="Passo"/>
                                    </div>
                                    <div className="ac-journey__mobile-text">
                                        <p dangerouslySetInnerHTML={{__html: this.data.steps[4].text}}></p>
                                    </div>
                                </div>
                                <div className="ac-journey__mobile-card">
                                    <div className="ac-journey__mobile-image">
                                        <img src={this.sixthStep} alt="Passo"/>
                                    </div>
                                    <div className="ac-journey__mobile-text">
                                        <p dangerouslySetInnerHTML={{__html: this.data.steps[5].text}}></p>
                                        <Link className="br-button primary mt-3" type="button"
                                              aria-label={this.data.steps[5].action.title}
                                              to={this.data.steps[5].action.uri}>
                                            {this.data.steps[5].action.title}
                                        </Link>
                                    </div>
                                </div>
                                <div className="ac-journey__mobile-card">
                                    <div className="ac-journey__mobile-image">
                                        <img src={this.seventhStep} alt="Passo"/>
                                    </div>
                                    <div className="ac-journey__mobile-text">
                                        <p dangerouslySetInnerHTML={{__html: this.data.steps[6].text}}></p>
                                        <Link className="br-button primary mt-3" type="button"
                                              aria-label={this.data.steps[6].action.title}
                                              to={this.data.steps[6].action.uri}>
                                            {this.data.steps[6].action.title}
                                        </Link>
                                    </div>
                                </div>
                                <div className="ac-journey__mobile-card">
                                    <div className="ac-journey__mobile-image">
                                        <img src={this.eighthStep} alt="Passo"/>
                                    </div>
                                    <div className="ac-journey__mobile-text">
                                        <p dangerouslySetInnerHTML={{__html: this.data.steps[7].text}}></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                { !process.env.HIDE_ELECTORAL_CONTENT 
                    ?
                    <div className="container-fluid np ac-elevation">
                        <div className="ac-journey__bottom shadow-sm-up">
                            <div className="row">
                                <div className="col">
                                    <div className="container-lg">
                                        <div className="row">
                                            <div
                                                className="col-12 col-sm-12 col-md-5 ac-journey__bottom-avatar-container relative">
                                                <div className="ac-journey__bottom-speech">
                                                    <blockquote
                                                        className="ac-oval-speech ac-oval-speech--rounded ac-oval-speech--white-2 ac-oval-speech--left ac-oval-speech--medium-alt-2 d-none d-sm-none d-md-block d-lg-block d-xl-block">
                                                        <p>{this.data.balloon2.text}</p>
                                                    </blockquote>
                                                    <blockquote
                                                        className="ac-oval-speech ac-oval-speech--rounded ac-oval-speech--white-2 ac-oval-speech--bottom ac-oval-speech--medium-alt d-block d-sm-block d-md-none d-lg-none d-xl-none">
                                                        <p>{this.data.balloon2.textShort}</p>
                                                    </blockquote>
                                                </div>
                                                <div
                                                    className="ac-journey__bottom-avatar d-none d-sm-none d-md-block d-lg-block d-xl-block">
                                                    <img src={this.avatarBottom} alt="Avatar"/>
                                                </div>
                                                <div
                                                    className="ac-journey__bottom-avatar-mobile d-block d-sm-block d-md-none d-lg-none d-xl-none">
                                                    <img src={this.avatarBottomMobile} alt="Avatar"/>
                                                </div>
                                            </div>
                                            <div className="col-12 col-sm-12 col-md-7">
                                                <div className="row ac-journey__videos">
                                                    <div className="col-12 col-sm-12 col-md-6 ac-journey__video">
                                                        <iframe src={this.data.videos[0].uri}
                                                                title={this.data.videos[0].text}
                                                                frameBorder="0"
                                                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                                allowFullScreen></iframe>
                                                    </div>
                                                    <div className="col-12 col-sm-12 col-md-6 ac-journey__video">
                                                        <iframe src={this.data.videos[1].uri}
                                                                title={this.data.videos[1].text}
                                                                frameBorder="0"
                                                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                                allowFullScreen></iframe>
                                                    </div>
                                                    <div className="col-12 col-sm-12 col-md-6 ac-journey__video">
                                                        <iframe src={this.data.videos[2].uri}
                                                                title={this.data.videos[2].text}
                                                                frameBorder="0"
                                                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                                allowFullScreen></iframe>
                                                    </div>
                                                    <div className="col-12 col-sm-12 col-md-6 ac-journey__video">
                                                        <iframe src={this.data.videos[3].uri}
                                                                title={this.data.videos[3].text}
                                                                frameBorder="0"
                                                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                                allowFullScreen></iframe>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    ''
                }
                <div className="container-fluid np ac-elevation">
                    <Contact/>
                </div>
            </div>
        );
    };
}

export default Journey;
