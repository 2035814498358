import * as React from 'react';
import {MenuModel} from '../../models/menu.model';
import {MenuLinkModel} from '../../models/menu-link.model';
import {Link} from 'react-router-dom';

import * as dsgov from '@govbr/dsgov/dist/dsgov.min';

import './footer.scss';

interface State {
    title?: string;
}

interface Props {
    title?: string;
}

class Footer extends React.Component<Props, State> {

    readonly data: MenuModel = require('../../assets/mock/menu.json');

    readonly govLogo = require('../../assets/img/govbr.png');

    readonly mecGovLogo = require('../../assets/img/logo_mec.png');

    readonly mecLogo = require('../../assets/img/marca_governo_federal.png');

    readonly facebookIcon = require('../../assets/img/ic_facebook.png');

    readonly instagramIcon = require('../../assets/img/ic_instagram.png');

    readonly twitterIcon = require('../../assets/img/ic_twitter.png');

    readonly youtubeIcon = require('../../assets/img/ic_youtube.png');

    state: State = {};

    init = (): void => {
        this.registerListerners();

        this.initFooter();
    };

    registerListerners = (): void => {
        console.debug('Registrando listeners...');
    };

    initFooter = (): void => {
        const listFooter = [];

        for (const brFooter of window.document.querySelectorAll('.br-footer')) {
            listFooter.push(new dsgov.BRFooter('br-footer', brFooter));
        }
    };

    componentDidMount = (): void => {
        this.init();
    };

    render = () => {
        return (
            <footer className="ac-footer br-footer">
                <div className="container-lg">
                    <div className="logo">
                        <img height="48" src={this.govLogo} alt="Governo Federal - logo"/>
                    </div>
                    <div className="br-list horizontal" data-toggle="data-toggle" data-sub="data-sub">
                        { this.data.menu.map((item: MenuLinkModel, index: number) => (
                            <div key={`m-${index}`} className="col-2">
                                <a className="br-item header">
                                    <div className="content text-down-01 text-bold text-uppercase">
                                        {item.title}
                                    </div>
                                    <div className="support">
                                        <i className="fas fa-angle-down" aria-hidden="true"></i>
                                    </div>
                                </a>
                                <div className="br-list">
                                    <span className="br-divider d-md-none"></span>

                                    { item.children.map((child: MenuLinkModel, index: number) => (
                                        !child.isExternal
                                            ?
                                            <Link key={`m-${index}`} className="br-item" to={child.uri}>
                                                <div className="content">
                                                    {child.title}
                                                </div>
                                            </Link>
                                            :
                                            <a key={`m-${index}`} className="br-item" href={child.uri}>
                                                <div className="content">
                                                    {child.title}
                                                </div>
                                            </a>

                                    ))}

                                    <span className="br-divider d-md-none"></span>
                                </div>
                            </div>
                        ))}
                    </div>


                    { this.data.social
                        ?
                        <div className="d-none d-sm-block">
                            <div className="row align-items-end justify-content-between py-5">
                                <div className="col social-network">
                                    <p className="text-up-01 text-extra-bold text-uppercase">
                                        Redes Sociais
                                    </p>
                                        { this.data.social.map((item: MenuLinkModel, index: number) => (
                                            <a key={`s-${index}`} className="ac-footer__social-link mr-3"
                                               href={item.uri} aria-label={item.title}>
                                                { item.title === 'Facebook'
                                                    ?
                                                    <img height="32" src={this.facebookIcon} alt="Facebook"/>
                                                    :
                                                    ''
                                                }
                                                { item.title === 'Instagram'
                                                    ?
                                                    <img height="32" src={this.instagramIcon} alt="Instagram"/>
                                                    :
                                                    ''
                                                }
                                                { item.title === 'Twitter'
                                                    ?
                                                    <img height="32" src={this.twitterIcon} alt="Twitter"/>
                                                    :
                                                    ''
                                                }
                                                { item.title === 'Youtube'
                                                    ?
                                                    <img height="32" src={this.youtubeIcon} alt="Youtube"/>
                                                    :
                                                    ''
                                                }
                                            </a>
                                        ))}
                                </div>
                                <div className="col text-right">
                                    <a href="https://www.gov.br/mec">
                                            <img height="88" className="ml-4" src={this.mecLogo} alt="Ministério da Educação - logo"/>
                                    </a>
                                </div>
                            </div>
                        </div>
                        :
                        ''
                    }
                </div>
                <span className="br-divider my-3"></span>
                <div className="container-lg">
                    <div className="info">
                        <div className="text-down-01 text-medium pb-3" dangerouslySetInnerHTML={{__html: this.data.copyright}}>
                        </div>
                    </div>
                </div>
            </footer>
        );
    };
}

export default Footer;
