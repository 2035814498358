import * as React from 'react';
import Contact from '../../components/contact/contact';
import DocumentationModel from "../../models/documentation.model";
import GoogleAnalyticsService from '../../services/google-analytics.service';

import './documentation.scss';

interface State {
    data?: DocumentationModel;
    path?: string;
}

interface Props {
    path?: string;
}

class Documentation extends React.Component<Props, State> {

    readonly data: DocumentationModel = require('../../assets/mock/documentation.json');

    readonly avatar = require('../../assets/img/documentacao_avatar.png');

    readonly sisuLogo = require('../../assets/img/logo_sisu.svg');

    readonly prouniLogo = require('../../assets/img/logo_prouni.svg');

    readonly fiesLogo = require('../../assets/img/logo_fies.svg');

    readonly pdfSisu = require('../../assets/download/SISU_documentacao.pdf');

    readonly pdfFies = require('../../assets/download/FIES_documentacao.pdf');

    readonly pdfProuni = require('../../assets/download/PROUNI_documentacao.pdf');

    readonly cardImages = [this.sisuLogo, this.prouniLogo, this.fiesLogo];

    state: State = {};

    googleAnalyticsService: GoogleAnalyticsService;

    init = (): void => {
        this.registerListerners();
    };

    registerListerners = (): void => {
        this.setState({
            data: this.data,
        });
    };

    registerGooglePageView = (): void => {
        this.googleAnalyticsService = GoogleAnalyticsService.getInstance();
        this.googleAnalyticsService.setPageView(this.data.title, window.location.pathname);
    };

    download = (alt: string): void => {

        if (alt === 'SISU') {
            window.open(this.pdfSisu, '_blank');
        }
        if (alt === 'FIES') {
            window.open(this.pdfFies, '_blank');
        }
        if (alt === 'PROUNI') {
            window.open(this.pdfProuni, '_blank');
        }
    }

    componentDidMount = (): void => {
        this.init();
        this.registerGooglePageView();
    };

    componentDidUpdate = (prevProps: Props): void => {
        if (this.props.path !== prevProps.path) {
            this.init();
        }
    };

    render = () => {
        return (
            <div className="ac-documentation main-content d-flex flex-column justify-content-between pt-2" id="main-content">
                <div className="container-lg d-flex flex-column flex-grow-1">
                    <div className="row">
                        <div className="col-12">
                            <h1 dangerouslySetInnerHTML={{__html: this.state.data?.title}}/>
                            <p>{this.state.data?.subTitle}</p>
                        </div>
                    </div>
                    <div className="row flex-grow-1">
                        <div className="col-12 col-sm-12 col-md-3 order-12 order-sm-12 order-md-0 mt-6 ac-documentation__avatar">
                            <img src={this.avatar} alt="Ilustração MEC"/>
                        </div>

                        {this.state.data?.cards?.map((card, index) => (
                            <div key={`ci-${index}`} className="col-12 col-sm-12 col-md-3 mt-6">
                                <div className="ac-card ac-card--red br-card">
                                    <div className="card-header">
                                        <img src={this.cardImages[index]} alt={card.header.alt}/>
                                    </div>
                                    <div className="card-content">
                                        <p dangerouslySetInnerHTML={{__html: card.text}}/>
                                    </div>
                                    <div className="card-footer">
                                        <button className="br-button primary" onClick={() => this.download(card.header.alt)}>
                                            {card.button.title}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        ))}


                    </div>
                </div>
                <div className="container-fluid np ac-elevation">
                    <Contact />
                </div>
            </div>
        );
    };
}

export default Documentation;
