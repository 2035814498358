import * as React from 'react';
import Contact from '../../components/contact/contact';
import {SearchModel} from '../../models/search.model';
import GoogleAnalyticsService from '../../services/google-analytics.service';
import {Link} from 'react-router-dom';

import './advanced-search.scss';

interface State {
    title?: string;
    path?: string;
}

interface Props {
    path?: string;
}

class AdvancedSearch extends React.Component<Props, State> {

    readonly data: SearchModel = require('../../assets/mock/advanced-search.json');

    readonly mecConectaLogo = require('../../assets/img/logo_mec_conecta_bg_azul.svg');

    state: State = {};

    googleAnalyticsService: GoogleAnalyticsService;

    init = (): void => {
        this.registerListerners();
    };

    registerListerners = (): void => {
        console.debug('Registrando listeners...');
    };

    registerGooglePageView = (): void => {
        this.googleAnalyticsService = GoogleAnalyticsService.getInstance();
        this.googleAnalyticsService.setPageView(this.data.title, window.location.pathname);
    };

    componentDidMount = (): void => {
        this.init();
        this.registerGooglePageView();
    };

    componentDidUpdate = (prevProps: Props): void => {
        if (this.props.path !== prevProps.path) {
            this.init();
        }
    };

    render = () => {
        return (
            <div className="ac-advanced-search main-content d-flex flex-column justify-content-between pt-2" id="main-content">
                <div className="container-lg d-flex flex-column flex-grow-1">
                    <div className="row">
                        <div className="col-12 col-sm-12 col-md-6">
                            <h1>
                                {this.data.title}
                            </h1>
                            <p dangerouslySetInnerHTML={{__html: this.data.subTitle}}></p>
                            <p dangerouslySetInnerHTML={{__html: this.data.description}}></p>
                        </div>
                        {/* <div className="col-12 col-sm-12 col-md-6 ac-advanced-search__top-action">
                            <div className="br-magic-button">
                                <Link className="br-button primary" type="button" to={'/programas'}>
                                    <span className="d-none d-sm-none d-md-none d-lg-block">{this.data.action.title}</span>
                                    <span className="d-block d-sm-block d-md-block d-lg-none">{this.data.action.titleShort}</span>
                                </Link>
                            </div>
                        </div>
                         */ }
                        <div className="col-12 col-sm-12 col-md-6 ac-advanced-search__top-action">
                            <div class="row">
                            <div class="col-12 col-sm-12 col-lg-12">    
                                <div className="br-magic-button">
                                    <Link className="br-button primary" type="button" to={'/programas'}>
                                        <span className="d-none d-sm-none d-md-none d-lg-block">{this.data.action.title}</span>
                                        <span className="d-block d-sm-block d-md-block d-lg-none">{this.data.action.titleShort}</span>
                                    </Link>
                                </div>
                            </div>
                            </div>
                            <p></p>
                            <p></p>
                            <p></p>        
                            <div class="row">
                                 <div class="col-1 col-sm-1 col-lg-2"></div>
                                 <div class="col-4 col-sm-4 col-lg-5">
                                 <p>Informações sobre as instituições de ensino:</p>
                                 </div>
                             <div class="col-7 col-sm-7 col-lg-5">
                              <div class="text-center">   
                                    <a href="https://conecta.mec.gov.br">
                                        <img className="ac-contrast" src={this.mecConectaLogo} alt="MEC_conecta" />
                                    </a>
                                </div>
                              </div>
                              </div>

                        </div>    

                    </div>
                    <div className="row">
                        <div className="col-12 ac-advanced-search__bi mt-4">
                            <iframe title={this.data.iframe.title}
                                    src={this.data.iframe.uri}
                                    frameBorder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen>
                            </iframe>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 text-center text-center mt-6">
                            <p className="nm" dangerouslySetInnerHTML={{__html: this.data.legend}}></p>
                        </div>
                    </div>
                </div>
                <div className="container-fluid np ac-elevation mt-6">
                    <Contact />
                </div>
            </div>
        );
    };
}

export default AdvancedSearch;
