import * as React from 'react';

import './top-nav.scss';

interface State {
    title?: string;
}

interface Props {
    title?: string;
}

class TopNav extends React.Component<Props, State> {

    state: State = {};

    init = (): void => {
        this.registerListerners();
    };

    registerListerners = (): void => {
        console.debug('Registrando listeners...');
    };

    componentDidMount = (): void => {
        this.init();
    };

    render = () => {
        return (
            <nav className="br-skiplink">
                <a className="br-item" href="#main-content" accessKey="1">
                    Ir para o conteúdo (1/4) <span className="br-tag text ml-1">1</span>
                </a>
                <a className="br-item" href="#header-navigation" accessKey="2">
                    Ir para o menu (2/4) <span className="br-tag text ml-1">2</span>
                </a>
                <a className="br-item" href="#main-searchbox" accessKey="3">
                    Ir para a busca (3/4) <span className="br-tag text ml-1">3</span>
                </a>
                <a className="br-item" href="#footer" accessKey="4">
                    Ir para o rodapé (4/4) <span className="br-tag text ml-1">4</span>
                </a>
            </nav>
        );
    };
}

export default TopNav;
