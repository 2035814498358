import axios from 'axios';

class MockService {

    static instance: MockService = null;

    readonly host = process.env.MOCK_HOST;

    static getInstance() {
        if (MockService.instance == null) {
            MockService.instance = new MockService();
        }

        return this.instance;
    }

    get = (endpoint: string): Promise<any> => {
        const uri = this.host + endpoint;
        return axios.get(uri);
    }
}

export default MockService;
