import * as React from 'react';
import Contact from '../../components/contact/contact';
import {AboutModel} from '../../models/about.model';
import GoogleAnalyticsService from '../../services/google-analytics.service';
import {Link} from 'react-router-dom';

import './about.scss';

interface State {
    title?: string;
    path?: string;
}

interface Props {
    path?: string;
}

class About extends React.Component<Props, State> {

    readonly data: AboutModel = require('../../assets/mock/about.json');

    readonly icons = [{
        on: require('../../assets/img/about_01_on.svg'),
        off: require('../../assets/img/about_01_off.svg')
    }, {
        on: require('../../assets/img/about_02_on.svg'),
        off: require('../../assets/img/about_02_off.svg')
    }, {
        on: require('../../assets/img/about_03_on.svg'),
        off: require('../../assets/img/about_03_off.svg')
    }, {
        on: require('../../assets/img/about_04_on.svg'),
        off: require('../../assets/img/about_04_off.svg')
    }, {
        on: require('../../assets/img/about_05_on.svg'),
        off: require('../../assets/img/about_05_off.svg')
    }, {
        on: require('../../assets/img/about_06_on.svg'),
        off: require('../../assets/img/about_06_off.svg')
    }]

    state: State = {};

    googleAnalyticsService: GoogleAnalyticsService;

    init = (): void => {
        this.registerListerners();
    };

    registerListerners = (): void => {
        console.debug('Registrando listeners...');
    };

    registerGooglePageView = (): void => {
        this.googleAnalyticsService = GoogleAnalyticsService.getInstance();
        this.googleAnalyticsService.setPageView(this.data.title, window.location.pathname);
    };

    componentDidMount = (): void => {
        this.init();
        this.registerGooglePageView();
    };

    componentDidUpdate = (prevProps: Props): void => {
        if (this.props.path !== prevProps.path) {
            this.init();
        }
    };

    render = () => {
        return (
            <div className="ac-about main-content d-flex flex-column justify-content-between pt-2" id="main-content">
                <div className="container-lg d-flex flex-column flex-grow-1">
                    <div className="row">
                        <div className="col-12 col-sm-12 col-md-6">
                            <h1>
                                {this.data.title}
                            </h1>
                        </div>
                        <div className="col-12 col-sm-12 col-md-6 ac-about__top-action">
                            <div className="br-magic-button">
                                <Link className="br-button primary" type="button" to={'/programas'}>
                                    <span className="d-none d-sm-none d-md-none d-lg-block">Acessar página de inscrições dos programas</span>
                                    <span
                                        className="d-block d-sm-block d-md-block d-lg-none">Inscrições dos programas</span>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-sm-12 col-md-7 col-lg-8 mt-6">
                            <div className="ac-about__content">
                                {/* <div className="ac-about__title">
                                    <strong>
                                        {this.data.subtitle}
                                    </strong>
                                </div> */}
                                <div className="ac-about__text" dangerouslySetInnerHTML={{__html: this.data.text}}>
                                </div>
                            </div>
                            <div className="ac-about__timeline">
                                {this.data.steps?.map((item, index) => (
                                    <div key={`as-${index}`}
                                         className={`ac-about__timeline-item ac-about__timeline-item--${item.status}`}>
                                        {index === 0 ?
                                            <div className="ac-about__timeline-here">
                                                Nos estamos neste ponto!
                                            </div>
                                            : ''
                                        }
                                        <div className="ac-about__timeline-circle">
                                            {item.status === 'active' || item.status === 'filled'
                                                ?
                                                <img src={this.icons[index].on} alt={`Passo 0${index}`}/>
                                                :
                                                <img src={this.icons[index].off} alt={`Passo 0${index}`}/>
                                            }
                                        </div>
                                        <div className="ac-about__timeline-info">
                                            <span>{index < 10 ? '0' + (index + 1) : index + 1}</span>
                                            <span dangerouslySetInnerHTML={{__html: item.text}}/>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                        { !process.env.HIDE_ELECTORAL_CONTENT || process.env.HIDE_ELECTORAL_CONTENT === 'false'
                            ?
                            <div className="col-12 col-sm-12 col-md-5 col-lg-4 mt-6">
                                <div className="ac-about__video">
                                    <iframe src={this.data.video?.uri}
                                            title={this.data.video?.text}
                                            frameBorder="0"
                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                            allowFullScreen></iframe>
                                </div>
                            </div>
                            :
                            ''
                        }
                    </div>
                </div>
                <div className="container-fluid np ac-elevation mt-6">
                    <Contact/>
                </div>
            </div>
        );
    };
}

export default About;
