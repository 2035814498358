import FaqTopics from './faq-topics.model';

class ProgramFaqModel {

    title: string;

    alias: string;

    faqGroups: Array<FaqTopics>;
}

export default ProgramFaqModel;
