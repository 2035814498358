class LocalStorageService {

    static instance: LocalStorageService = null;

    static getInstance() {
        if (LocalStorageService.instance == null) {
            LocalStorageService.instance = new LocalStorageService();
        }

        return this.instance;
    }

    get = (key: string): string => {
        return localStorage.getItem(key);
    }

    save = (key: string, value: string): void => {
        localStorage.setItem(key, value);
    }

    remove = (key: string): void => {
        localStorage.removeItem(key);
    }
}

export default LocalStorageService;
