import * as React from 'react';
import Contact from '../../components/contact/contact';
import ProgramFaqModel from '../../models/program.faq.model';
import MockService from '../../services/mock.service';
import GoogleAnalyticsService from '../../services/google-analytics.service';

import './program-faq.scss';

interface State {
    alias?: string;
    data?: ProgramFaqModel;
    path?: string;
}

interface Props {
    path?: string;
}

class ProgramFaq extends React.Component<Props, State> {

    readonly sisuMock = require('../../assets/mock/sisu-faq.json');

    readonly prouniMock = require('../../assets/mock/prouni-faq.json');

    readonly fiesMock = require('../../assets/mock/fies-faq.json');

    state: State = {};

    alias: string;

    data: ProgramFaqModel = new ProgramFaqModel();

    mockService: MockService;

    googleAnalyticsService: GoogleAnalyticsService;

    init = (): void => {
        this.mockService = MockService.getInstance();
        this.registerListerners();
    };

    registerListerners = (): void => {
        const alias = location.pathname.split('/')[1];
        this.alias = alias;
        // const endpoint = `${alias}-faq.json`;
        //
        // this.mockService.get(endpoint)
        //     .then((response: any) => {
        //         if (response && response.data) {
        //             const data: ProgramFaqModel = response.data;
        //             this.data = data;
        //
        //             this.setState({
        //                 data: data,
        //                 alias: alias
        //             });
        //
        //             console.log(this.data);
        //         }
        //     })
        //     .catch((err: any) => {
        //         console.log(err);
        //     });

        let data: ProgramFaqModel;

        if (alias === 'sisu') {
            data = this.sisuMock;
            this.data = data;
        }

        if (alias === 'prouni') {
            data = this.prouniMock;
            this.data = data;
        }

        if (alias === 'fies') {
            data = this.fiesMock;
            this.data = data;
        }

        this.setState({
            data: data,
            alias: alias
        });

        window.addEventListener('scroll', (event) => {
            this.handleFloatingMenu();
        }, true);

        window.addEventListener('resize', (event) => {
            this.handleFloatingMenu();
        }, true);
    };

    registerGooglePageView = (): void => {
        this.googleAnalyticsService = GoogleAnalyticsService.getInstance();
        this.googleAnalyticsService.setPageView(this.data.title, window.location.pathname);
    };

    handleFloatingMenu = (): void => {
        const groupContainerElement: any = document.querySelector('.ac-program-faq__group-container');
        const menuContainerElement: any = document.querySelector('.ac-program-faq__menu-container');
        const menuElement: any = document.querySelector('.ac-program-faq__menu');

        if (window.innerWidth > 991) {
            menuElement.style.width = menuContainerElement.offsetWidth - 24 + 'px';

            if (groupContainerElement.getBoundingClientRect().top < 160 && (!menuElement.classList.contains('ac-program-faq__menu--fixed') && groupContainerElement.getBoundingClientRect().bottom > menuElement.getBoundingClientRect().height + 100)) {
                menuElement.classList.add('ac-program-faq__menu--fixed');
            } else if (groupContainerElement.getBoundingClientRect().top >= 160) {
                menuElement.classList.remove('ac-program-faq__menu--fixed');
            } else if (groupContainerElement.getBoundingClientRect().bottom <= (menuElement.getBoundingClientRect().height + 100)) {
                menuElement.classList.remove('ac-program-faq__menu--fixed');
            }
        } else {
            menuElement.style.width = '100%';
            menuElement.classList.remove('ac-program-faq__menu--fixed');
        }
    };

    handleAnchorClick = (id: string): void => {
        window.history.replaceState(null, '', `#${id}`);
        window.document.getElementById(id).scrollIntoView({
            behavior: 'smooth'
        });
    };

    componentDidMount = (): void => {
        this.init();
        this.registerGooglePageView();
    };

    componentDidUpdate = (prevProps: Props): void => {
        if (this.props.path !== prevProps.path) {
            this.init();
            this.registerGooglePageView();

            const hash = window.location.hash;
            if (hash) {
                this.handleAnchorClick(hash.replace('#', ''));
            }
        }
    };

    render = () => {
        return (
            <div className="ac-program-faq main-content d-flex flex-column justify-content-between pt-2" id="main-content">
                <div className="container-lg">
                    <div className="row">
                        <div className="col-12">
                            <h1>
                                {this.data.title}
                            </h1>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-sm-12 col-md-8 order-12 order-sm-12 order-md-0 ac-program-faq__group-container">
                            {this.data.faqGroups?.map((item, index) => (
                                <div key={`g-${index}`} id={item.id} className="ac-program-faq__group">
                                    <div className="ac-program-faq__group-title">
                                        {item.title}
                                    </div>

                                    {item.topics?.map((topic, ti) => (
                                        <div id={topic.id} key={`t-${ti}`} className="ac-program-faq__content">
                                            <div className="ac-program-faq__title">
                                                <strong>{topic.title}</strong>
                                            </div>
                                            <div className="ac-program-faq__text">
                                                <p dangerouslySetInnerHTML={{ __html: topic.text }}></p>
                                            </div>
                                            {topic.children?.map((innerTopic, iti) => (
                                                <div key={`ti-${iti}`} className="ac-program-faq__inner-content">
                                                    <div className="ac-program-faq__title">
                                                        <strong>{innerTopic.title}</strong>
                                                    </div>
                                                    <div className="ac-program-faq__text">
                                                        <p dangerouslySetInnerHTML={{ __html: innerTopic.text }}></p>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    ))}

                                    <a className="ac-program-faq__back" onClick={() => { this.handleAnchorClick('ac-app') }}>
                                        <strong>Voltar para o topo</strong>
                                    </a>
                                </div>
                            ))}
                        </div>
                        <div className="col-12 col-sm-12 col-md-4 ac-program-faq__menu-container relative">
                            <div className="ac-program-faq__menu">
                                {this.data.faqGroups?.map((item, index) => (
                                    <a key={`gm-${index}`} onClick={() => { this.handleAnchorClick(item.id) }}>
                                        <span>{item.title}</span>
                                        <i className="fas fa-angle-right" aria-hidden="true"></i>
                                    </a>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container-fluid np ac-elevation mt-6">
                    <Contact alias={this.alias} />
                </div>
            </div>
        );
    };
}

export default ProgramFaq;
