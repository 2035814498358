import * as React from 'react';
import GoogleAnalyticsService from '../../services/google-analytics.service';
import {Link} from 'react-router-dom';

import './not-found.scss';

interface State {
    title?: string;
}

interface Props {
    title?: string;
}

class NotFound extends React.Component<Props, State> {

    readonly errorImg = require('../../assets/img/error404.png');

    state: State = {};

    googleAnalyticsService: GoogleAnalyticsService;

    init = (): void => {
        this.registerListerners();
        this.registerGooglePageView();
    };

    registerListerners = (): void => {
        console.debug('Registrando listeners...');
    };

    registerGooglePageView = (): void => {
        this.googleAnalyticsService = GoogleAnalyticsService.getInstance();
        this.googleAnalyticsService.setPageView('Página de erro 404', window.location.pathname);
    };

    componentDidMount = (): void => {
        this.init();
    };

    render = () => {
        return (
            <div id="main-content" className="ac-not-found main-content d-flex flex-column justify-content-between pl-sm-3 pt-4">
                <div className="container-lg">
                    <div className="row">
                        <div className="col-12 mb-4">
                            <div className="template-erro mb-6">
                                <div className="row">
                                    <div className="col-sm-4 d-flex align-items-center justify-content-center">
                                        <div className="mt-4 mt-sm-0">
                                            <img src={this.errorImg} alt="Página não encontrada"/>
                                        </div>
                                    </div>
                                    <div className="col text-center text-sm-left">
                                        <div className="text-support-03">
                                            <p className="text-up-06 text-semi-bold my-3">
                                                Estamos constrangidos em te ver por aqui
                                            </p>
                                        </div>
                                        <div className="text-secondary-06">
                                            <p className="text-up-03 text-medium my-3">
                                                Mas, podemos ajudá-lo a encontrar o que está procurando de outra forma
                                            </p>
                                        </div>
                                        <p className="my-3">
                                            Talvez você tenha se equivocado ao digitar o endereço URL ou quem sabe nós tenhamos cometido uma falha por aqui. Se possível, relate o erro para que possamos sempre estar melhorando.
                                        </p>
                                    </div>
                                </div>
                                <div className="row my-5 text-center justify-content-lg-center">
                                    <div className="col-lg-auto mt-1 mt-lg-0">
                                        <Link className="br-button crumb" type="button" to={'/'}>
                                            <i className="fas fa-home" aria-hidden="true"></i>
                                            <span>Ir para Página Principal</span>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };
}

export default NotFound;
