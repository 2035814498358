import Home from '../pages/home/home';
import * as React from 'react';
import Journey from '../pages/journey/journey';
import About from '../pages/about/about';
import Programs from '../pages/programs/programs';
import Documentation from '../pages/documentation/documentation';
import AdvancedSearch from '../pages/advanced-search/advanced-search';
import NotFound from '../pages/not-found/not-found';
import RouteModel from '../models/route.model';
import Program from '../pages/program/program';
import ProgramFaq from '../pages/program-faq/program-faq';

const Routes: Array<RouteModel> = [{
    path: '/',
    title: 'Página principal',
    component: <Home/>,
    exact: true,
    showInBreadcrumb: false
}, {
    path: '/formas-de-acesso',
    title: 'Formas de Acesso ao Ensino Superior',
    component: <Journey/>,
    exact: false,
    showInBreadcrumb: true
}, {
    path: '/acesso-unico',
    title: 'Projeto Acesso Único',
    component: <About/>,
    exact: false,
    showInBreadcrumb: true
}, {
    path: '/programas',
    title: 'Acesso ao Ensino Superior: SISU, PROUNI e FIES',
    component: <Programs/>,
    exact: false,
    showInBreadcrumb: true
}, {
    path: '/documentacao',
    title: 'Documentação',
    component: <Documentation/>,
    exact: false,
    showInBreadcrumb: true
}, {
    path: '/sisu',
    title: 'SISU',
    component: <Program/>,
    exact: false,
    showInBreadcrumb: true
}, {
    path: '/sisu/duvidas',
    title: 'Dúvidas sobre o SISU',
    component: <ProgramFaq/>,
    exact: false,
    showInBreadcrumb: true
}, {
    path: '/prouni',
    title: 'PROUNI',
    component: <Program/>,
    exact: false,
    showInBreadcrumb: true
}, {
    path: '/prouni/duvidas',
    title: 'Dúvidas sobre o PROUNI',
    component: <ProgramFaq/>,
    exact: false,
    showInBreadcrumb: true
}, {
    path: '/fies',
    title: 'FIES',
    component: <Program/>,
    exact: false,
    showInBreadcrumb: true
}, {
    path: '/fies/duvidas',
    title: 'Dúvidas sobre o FIES',
    component: <ProgramFaq/>,
    exact: false,
    showInBreadcrumb: true
}, {
    path: '/busca',
    title: 'Busca Avançada',
    component: <AdvancedSearch/>,
    exact: false,
    showInBreadcrumb: true
}, {
    path: '*',
    title: 'Página não encontrada',
    component: <NotFound/>,
    exact: false,
    showInBreadcrumb: false
}];

export default Routes;
