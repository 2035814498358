import * as React from 'react';
import * as ReactDOM from 'react-dom';

/**********
 * Themes styles
 ***********/

/**********
 * Design system styles
 ***********/

/**********
 * General styles
 ***********/

import './styles.scss';

/************/

import App from './src/app';
import {BrowserRouter} from 'react-router-dom';
// import * as dotenv from 'dotenv';
//
// dotenv.config({
//     path: process.env.NODE_ENV === "DEV" ? ".env.local" : ".env"
// });

ReactDOM.render((
    <BrowserRouter>
        <App />
    </BrowserRouter>
), document.getElementById('ac-root'));
