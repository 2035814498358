import * as React from 'react';
import Contact from '../../components/contact/contact';
import {ProgramsModel} from '../../models/programs.model';
import ProgramButtonModel from '../../models/program-button.model';
import GoogleAnalyticsService from '../../services/google-analytics.service';

import './programs.scss';

interface State {
    data?: ProgramsModel
    path?: string;
}

interface Props {
    path?: string;
}

class Programs extends React.Component<Props, State> {

    readonly data: ProgramsModel = require('../../assets/mock/programs.json');

    readonly sisuLogo = require('../../assets/img/logo_sisu.svg');

    readonly prouniLogo = require('../../assets/img/logo_prouni.svg');

    readonly fiesLogo = require('../../assets/img/logo_fies.svg');

    state: State = {};

    googleAnalyticsService: GoogleAnalyticsService;

    init = (): void => {
        this.registerListerners();
    };

    registerListerners = (): void => {
        this.setState({
            data: this.data,
        });
    };

    registerGooglePageView = (): void => {
        this.googleAnalyticsService = GoogleAnalyticsService.getInstance();
        this.googleAnalyticsService.setPageView(this.data.title, window.location.pathname);
    };

    handleCLick = (uri: any): void => {
        window.location = uri;
    };

    canDisplayButton = (button: ProgramButtonModel) : boolean => {const now = new Date();
        debugger;
        let canDisplay = false;

        if (button.isForever) {
            canDisplay = true;
        } else {
            const startDate = new Date(button.startDate);
            const endDate = new Date(button.endDate);

            if (now >= startDate && now <= endDate) {
                canDisplay = true;
            }

            if (now > startDate && now > endDate) {
                canDisplay = false;
            }
        }

        return canDisplay;
    };

    componentDidMount = (): void => {
        this.init();
        this.registerGooglePageView();
    };

    componentDidUpdate = (prevProps: Props): void => {
        if (this.props.path !== prevProps.path) {
            this.init();
        }
    };

    render = () => {
        return (
            <div className="ac-programs main-content d-flex flex-column justify-content-between pt-2" id="main-content">
                <div className="container-lg pb-6">
                    <div className="row">
                        <div className="col-12">
                            <h1>
                                {this.data.title}
                            </h1>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-sm-12 col-md-4 mt-6 ac-programs__cards">
                            <div className="ac-card ac-card--blue br-card">
                                <div className="card-header">
                                    <img height="100" src={this.sisuLogo} alt="SISU"/>
                                </div>
                                <div className="card-content">
                                    <p dangerouslySetInnerHTML={{__html: this.data.sisu.text}}></p>
                                </div>
                                <div className="card-footer">
                                    { this.data.sisu.buttons && this.data.sisu.buttons.length > 0
                                        ?
                                        this.data.sisu.buttons.map((item, index) => (
                                            <button key={`b-${index}`} className={`br-button primary ac-programs__button ${!this.canDisplayButton(item) ? 'ac-programs__button--hide' : ''}`} type="button" aria-label={item.text} onClick={() => this.handleCLick(item.uri)}>
                                                {item.text}
                                            </button>
                                        ))
                                        :
                                        ''
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-sm-12 col-md-4 mt-6">
                            <div className="ac-card ac-card--pink br-card">
                                <div className="card-header">
                                    <img height="100" src={this.prouniLogo} alt="PROUNI"/>
                                </div>
                                <div className="card-content">
                                    <p dangerouslySetInnerHTML={{__html: this.data.prouni.text}}></p>
                                </div>
                                <div className="card-footer">
                                    { this.data.prouni.buttons && this.data.prouni.buttons.length > 0
                                        ?
                                        this.data.prouni.buttons.map((item, index) => (
                                            <button key={`b-${index}`} className={`br-button primary ac-programs__button ${!this.canDisplayButton(item) ? 'ac-programs__button--hide' : ''}`} type="button" aria-label={item.text} onClick={() => this.handleCLick(item.uri)}>
                                                {item.text}
                                            </button>
                                        ))
                                        :
                                        ''
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-sm-12 col-md-4 mt-6">
                            <div className="ac-card ac-card--green br-card">
                                <div className="card-header">
                                    <img height="100" src={this.fiesLogo} alt="FIES"/>
                                </div>
                                <div className="card-content">
                                    <p dangerouslySetInnerHTML={{__html: this.data.fies.text}}></p>
                                </div>
                                <div className="card-footer">
                                    { this.data.fies.buttons && this.data.fies.buttons.length > 0
                                        ?
                                        this.data.fies.buttons.map((item, index) => (
                                            <button key={`b-${index}`} className={`br-button primary ac-programs__button ${!this.canDisplayButton(item) ? 'ac-programs__button--hide' : ''}`} type="button" aria-label={item.text} onClick={() => this.handleCLick(item.uri)}>
                                                {item.text}
                                            </button>
                                        ))
                                        :
                                        ''
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container-fluid np ac-elevation mt-6">
                    <Contact />
                </div>
            </div>
        );
    };
}

export default Programs;
