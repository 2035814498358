class RouteModel {

    path: string;

    title: string;

    component: any;

    exact: boolean;

    showInBreadcrumb: boolean;
}

export default RouteModel;
