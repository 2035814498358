import * as React from 'react';
import { Link } from "react-router-dom";
import routes from '../../routes/routes';
import RouteModel from '../../models/route.model';

import './breadcrumb.scss';

interface State {
    route?: RouteModel;
    treeRoute?: Array<TreeObject>;
    path?: string;
}

interface TreeObject {
    path: string,
    title: string,
    isFirst: boolean,
    isLast: boolean
}

interface Props {
    path?: string;
}

class Breadcrumb extends React.Component<Props, State> {

    state: State = {};

    route: RouteModel = new RouteModel();

    treeRoute: Array<TreeObject> = [];

    init = (): void => {
        this.registerListerners();
        this.handleRoute();
    };

    handleRoute = (): void => {
        const route = routes.find(r => r.path === location.pathname);
        this.route = route;

        const treeRoute: any = [];
        this.treeRoute = [];

        if (this.route) {
            const arr = this.route.path.split('/');
            let nextRoute = '';
            arr.forEach((ro, index) => {
                const match = nextRoute === '' ? '/' : nextRoute;
                const routeNode = routes.find(r => r.path === match);
                if (index < arr.length) {
                    nextRoute = nextRoute + '/' + arr[index + 1];
                }
                this.treeRoute.push({
                    path: routeNode.path,
                    title: routeNode.title,
                    isFirst: index === 0,
                    isLast: index === arr.length - 1
                })
            });
        }

        this.setState({
            route: route,
            treeRoute: treeRoute
        });
    };

    registerListerners = (): void => {
        console.debug('Registrando listeners...');
    };

    componentDidMount = (): void => {
        this.init();
    };

    componentDidUpdate = (prevProps: Props): void => {
        if (this.props.path !== prevProps.path) {
            this.handleRoute();
        }
    };

    render = () => {
        return (
            this.route
                ?
                <div className={`ac-breadcrumb br-breadcrumb ${!this.route.showInBreadcrumb ? 'd-none' : ''}`}>
                    <div className="container-lg">
                        <div className="row">
                            <div className="col-12">
                                <ul className="crumb-list">
                                    {this.treeRoute.map((route, key) => (
                                        route.isFirst
                                            ?
                                            <li key={key} className="crumb home">
                                                <Link className="br-button circle" to={route.path}>
                                                    <span className="sr-only">
                                                        Página inicial
                                                    </span>
                                                    <i className="icon fas fa-home"></i>
                                                </Link>
                                            </li>
                                            :
                                            !route.isLast
                                                ?
                                                <li key={key} className="crumb">
                                                    <i className="icon fas fa-chevron-right"></i>
                                                    <Link className="ml-2 mr-2" to={route.path}>{route.title}</Link>
                                                </li>
                                                :
                                                <li key={key} className="crumb" data-active="active">
                                                    <i className="icon fas fa-chevron-right"></i>
                                                    <span className="ml-2 mr-2">{route.title}</span>
                                                </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                :
                ''
        );
    };
}

export default Breadcrumb;
