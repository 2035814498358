import * as React from 'react';
import {AlertModel} from '../../models/alert.model';
import LocalStorageService from '../../services/local-storage.service';

import './alert.scss';

interface State {
    alerts?: Array<AlertModel>;
    activeAlert?: AlertModel;
    currentIndex?: number;
    path?: string;
}

interface Props {
    alerts?: Array<AlertModel>;
    path?: string;
}

class Alert extends React.Component<Props, State> {

    readonly alertsKey: string = 'ALERTS';

    readonly alertsMock: Array<AlertModel> = require('../../assets/mock/alerts.json');

    alerts: Array<AlertModel> = [];

    activeAlert: AlertModel;

    readAlertsIds: Array<string> = [];

    currentIndex: number = 0;

    localStorageService: LocalStorageService;

    state: State = {};

    init = (): void => {
        this.localStorageService = LocalStorageService.getInstance();
        this.checkAlerts();
    };

    checkAlerts = (): void => {
        const alerts: Array<AlertModel> = this.alertsMock;
        const alertsToShow: Array<AlertModel> = [];
        const localStorageData: string = this.localStorageService.get(this.alertsKey);

        this.readAlertsIds = JSON.parse(localStorageData);

        if (alerts && alerts.length > 0) {

            alerts.forEach((alert: AlertModel, index: number) => {
                if (!this.readAlertsIds) {
                    if (this.showAlert(alert)) {
                        alertsToShow.push(alert);
                    }
                } else if (!this.readAlertsIds.includes(alert.id) && this.showAlert(alert)) {
                    alertsToShow.push(alert);
                }
            });

            this.alerts = alertsToShow;
            this.activeAlert = alertsToShow[this.currentIndex];

            this.setState({
                alerts: this.alerts,
                activeAlert: this.activeAlert,
                currentIndex: this.currentIndex
            });
        }

        const element = window.document.querySelector('.ac-alert');

        if (this.alerts && this.alerts.length > 0 && element) {
            element.classList.add('ac-alert--active');
        } else if (element) {
            element.classList.remove('ac-alert--active');
        }
    };

    showAlert = (alert: AlertModel): boolean => {
        let show: boolean = false;

        const now = new Date();
        const startDate = new Date(alert.startDate);
        const endDate = new Date(alert.endDate);

        if ((!alert.paths || alert.paths.length === 0) && ((now >= startDate && now <= endDate))) {
            show = true;
        }

        if ((alert.paths && alert.paths.includes(this.props.path)) && ((now >= startDate && now <= endDate))) {
            show = true;
        }

        return show;
    }

    next = (): void => {
        this.saveAlert();
        if ((this.currentIndex) + 1 < this.alerts.length) {
            this.currentIndex++;
            this.activeAlert = this.alerts[this.currentIndex];

            this.setState({
                alerts: this.alerts,
                activeAlert: this.activeAlert,
                currentIndex: this.currentIndex
            });
        }
    };

    previous = (): void => {
        this.saveAlert();
        if (this.currentIndex > 0) {
            this.currentIndex--;
            this.activeAlert = this.alerts[this.currentIndex];

            this.setState({
                alerts: this.alerts,
                activeAlert: this.activeAlert,
                currentIndex: this.currentIndex
            });
        }
    };

    close = (): void => {
        this.saveAlert();

        const element = window.document.querySelector('.ac-alert');

        if (element) {
            element.classList.remove('ac-alert--active');
        }
    };

    saveAlert = (): void => {
        if (!this.activeAlert.isFixed) {

            if (this.readAlertsIds && !this.readAlertsIds.includes(this.activeAlert.id)) {
                this.readAlertsIds.push(this.activeAlert.id);
                this.localStorageService.save(this.alertsKey, JSON.stringify(this.readAlertsIds));
            } else if (!this.readAlertsIds) {
                this.readAlertsIds = [];
                this.readAlertsIds.push(this.activeAlert.id);
                this.localStorageService.save(this.alertsKey, JSON.stringify(this.readAlertsIds));
            }
        }
    }

    componentDidMount = (): void => {
        this.init();
    };

    componentDidUpdate = (prevProps: Props): void => {
        if (this.props.path !== prevProps.path) {
            this.init();
        }
    };

    render = () => {
        return (
            <div className="ac-alert">
                <div className="br-modal ac-alert__modal">
                    <div className="container-fluid p-1 p-sm-4">
                        <div className="br-modal-header">
                            { this.alerts.length > 1 ? <div className="ac-alert__step">{this.currentIndex + 1} / {this.alerts.length}</div> : '' }
                            <div className="br-modal-title" title="Retornar para página inicial?">
                                <span>{this.activeAlert?.title}</span>
                            </div>
                            <button className="br-button close circle small" type="button" data-dismiss="br-modal" aria-label="Close" onClick={() => this.close()}>
                                <i className="fas fa-times" aria-hidden="true"></i>
                            </button>
                        </div>
                        <div className="br-modal-body" dangerouslySetInnerHTML={{__html: this.activeAlert?.content}}>
                        </div>
                        <div className="br-modal-footer justify-content-end">
                            { this.alerts.length > 1
                                ?
                                <>
                                    { this.currentIndex !== 0
                                        ?
                                        <button className="br-button secondary small m-2" type="button" aria-label="Voltar" onClick={() => this.previous()}>
                                            Voltar
                                        </button>
                                        :
                                        ''
                                    }
                                    { this.currentIndex !== (this.alerts.length - 1)
                                        ?
                                        <button className="br-button primary small m-2" type="button" aria-label="Próximo" onClick={() => this.next()}>
                                            Próximo
                                        </button>
                                        :
                                        <button className="br-button primary small m-2" type="button" aria-label="Entendi" onClick={() => this.close()}>
                                            Entendi
                                        </button>
                                    }
                                </>
                                :
                                <button className="br-button primary small m-2" type="button" aria-label="Entendi" onClick={() => this.close()}>
                                    Entendi
                                </button>
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    };
}

export default Alert;
