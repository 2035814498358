import {
    Route,
    Routes,
    useLocation
} from 'react-router-dom';

import * as React from 'react';

import TopNav from './components/top-nav/top-nav';
import Header from './components/header/header';
import Menu from './components/menu/menu';
import Breadcrumb from './components/breadcrumb/breadcrumb';
import Footer from './components/footer/footer';
import Alert from './components/alert/alert';
import Home from './pages/home/home';
import Journey from './pages/journey/journey';
import About from './pages/about/about';
import Programs from './pages/programs/programs';
import Documentation from './pages/documentation/documentation';
import Program from './pages/program/program';
import ProgramFaq from './pages/program-faq/program-faq';
import AdvancedSearch from './pages/advanced-search/advanced-search';
import NotFound from './pages/not-found/not-found';

import * as gtag from './utils/gtag.util';

import './app.scss'


const App = () => {

    gtag.init();

    const [path, setPath] = React.useState('/');

    const location = useLocation();

    const closeMenu = () => {
        const element = window.document.querySelector('.ac-menu');

        if (element) {
            element.classList.remove('active');
        }
    };

    const resetScroll = () => {
        window.scrollTo({
            top: 0
        });
    };

    React.useEffect(() => {
        closeMenu();
        resetScroll();

        setPath(location.pathname);
    }, [location]);

    return (
        <div id="ac-app" className="ac-app template-base">
            <TopNav />
            <Header />
            <main className="ac-page-wrapper d-flex flex-fill" id="main">
                <Menu />
                <Breadcrumb path={path} />
                <Routes>
                    <Route path="/" element={ <Home path={path} /> }  />
                    <Route path="/formas-de-acesso" element={ <Journey path={path} /> }  />
                    <Route path="/acesso-unico" element={ <About path={path} /> }  />
                    <Route path="/programas" element={ <Programs path={path} /> }  />
                    <Route path="/documentacao" element={ <Documentation path={path} /> }  />
                    <Route path="/sisu" element={ <Program path={path} /> }  />
                    <Route path="/sisu/duvidas" element={ <ProgramFaq path={path} /> }  />
                    <Route path="/prouni" element={ <Program path={path} /> }  />
                    <Route path="/prouni/duvidas" element={ <ProgramFaq path={path} /> }  />
                    <Route path="/fies" element={ <Program path={path} /> }  />
                    <Route path="/fies/duvidas" element={ <ProgramFaq path={path} /> }  />
                    <Route path="/busca" element={ <AdvancedSearch path={path} /> }  />
                    <Route path="*" element={ <NotFound /> }  />
                </Routes>
            </main>
            <Footer />
            <Alert path={path} />
        </div>
    );
};

export default App;
