import * as React from 'react';
import LocalStorageService from '../../services/local-storage.service';
import {Link} from 'react-router-dom';

import * as dsgov from '@govbr/dsgov/dist/dsgov.min';

import './header.scss';

interface State {

    pageName?: string;

    pageSubtitle?: string;
}

interface Props {
    title?: string;
}

class Header extends React.Component<Props, State> {

    readonly govLogo = require('../../assets/img/govbr-logo-large.png');

    readonly contrasteKey = 'CONSTRAST';

    localStorageService: LocalStorageService;

    state: State = {};

    init = (): void => {
        this.localStorageService = LocalStorageService.getInstance();

        this.setState({
            pageName: 'Portal Único de Acesso ao Ensino Superior',
            pageSubtitle: 'Ministério da Educação'
        });

        this.registerListerners();
        this.initHeader();
    };

    initHeader = (): void => {
        const headerList = [];

        for (const brHeader of window.document.querySelectorAll('.br-header')) {
            headerList.push(new dsgov.BRHeader('br-header', brHeader))
        }
    };

    registerListerners = (): void => {
        const contrast = this.localStorageService.get(this.contrasteKey);

        if (contrast) {
            this.toggleConstrast();
        }

        window.addEventListener('click', (event: any) => {
            const parent = event.target.parentElement;

            if (parent && !parent.classList.contains('ac-header__dropdown')) {
                this.handleFloatingMenuClick();
            }
        });
    };

    toggleDropDown = (event: any): void => {
        this.handleFloatingMenuClick();

        const parent = event.target.parentElement;

        if (parent) {
            parent.classList.toggle('show');
            event.target.classList.toggle('active');
        }
    };

    toggleConstrast = (): void => {
        const constrastClass = 'ac-contrast';
        const body = window.document.querySelector('body');
        if (body.classList.contains(constrastClass)) {
            body.classList.remove(constrastClass);
            this.localStorageService.remove(this.contrasteKey);
        } else {
            body.classList.add(constrastClass);
            this.localStorageService.save(this.contrasteKey, 'ON');
        }

        this.handleFloatingMenuClick();
    };

    handleFloatingMenuClick = (): void => {
        const floatingMenuLeft = window.document.querySelector('.header-links.show');
        const floatingMenuRight = window.document.querySelector('.header-functions.show');

        if (floatingMenuLeft) {
            const button = floatingMenuLeft.querySelector('button.active');
            if (button) {
                button.classList.remove('active');
            }

            floatingMenuLeft.classList.remove('show');
        }

        if (floatingMenuRight) {
            const button = floatingMenuRight.querySelector('button.active');
            if (button) {
                button.classList.remove('active');
            }

            floatingMenuRight.classList.remove('show');
        }
    };

    componentDidMount = (): void => {
        this.init();
    };

    render = () => {
        return (
            <header className="ac-header br-header mb-4" id="header" data-sticky="data-sticky">
                <div className="container-lg">
                    <div className="header-top">
                        <div className="header-logo">
                            <img src={this.govLogo} alt="Governo Federal - logo"/>
                            <span className="br-divider vertical mx-half mx-sm-1"></span>
                            <div className="header-sign">Governo Federal</div>
                        </div>
                        <div className="header-actions">
                            <div className="header-links dropdown ac-header__dropdown">
                                <button className="br-button circle small" type="button"
                                        aria-label="Abrir Acesso Rápido" onClick={this.toggleDropDown}>
                                    <i className="fas fa-ellipsis-v" aria-hidden="true"></i>
                                </button>
                                <div className="br-list">
                                    <div className="header">
                                        <div className="title">Acesso Rápido</div>
                                    </div>
                                    <a className="br-item" href="https://www.gov.br/pt-br/orgaos-do-governo">
                                        Órgãos do Governo
                                    </a>
                                    <a className="br-item" href="https://www.gov.br/acessoainformacao/pt-br">
                                        Acesso a informação
                                    </a>
                                    <a className="br-item" href="http://www4.planalto.gov.br/legislacao/">
                                        Legislação
                                    </a>
                                    <a className="br-item" href="https://www.gov.br/governodigital/pt-br/acessibilidade-digital">
                                        Acessibilidade
                                    </a>
                                </div>
                            </div>
                            <span className="br-divider vertical mx-half mx-sm-1"></span>
                            <div className="header-functions dropdown ac-header__dropdown">
                                <button className="br-button circle small" type="button"
                                        aria-label="Abrir Funcionalidades do Sistema" onClick={this.toggleDropDown}>
                                    <i className="fas fa-th" aria-hidden="true"></i>
                                </button>
                                <div className="br-list">
                                    <div className="header">
                                        <div className="title">Funcionalidades do Sistema</div>
                                    </div>
                                    <div className="align-items-center br-item">
                                        <button className="br-button circle small" type="button"
                                                aria-label="Modo de contraste" onClick={() => this.toggleConstrast()}>
                                            <i className="fas fa-adjust" aria-hidden="true"></i>
                                            <span className="text">Modo de contraste</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="header-login">
                                <div className="header-sign-in">
                                    <a href="https://acesso.gov.br" className="br-sign-in small" type="button"
                                       data-trigger="login">
                                        <i className="fas fa-user" aria-hidden="true"></i>
                                        <span className="d-sm-inline">Entrar</span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="header-bottom">
                        <div className="header-menu">
                            <div className="header-menu-trigger" id="header-navigation">
                                <button className="br-button small circle" type="button" aria-label="Menu" data-toggle="menu" data-target="#main-navigation" id="navigation">
                                    <i className="fas fa-bars" aria-hidden="true"></i>
                                </button>
                            </div>
                            <div className="header-info">
                                <div className="header-title">
                                    <Link to="/">
                                        {this.state.pageName}
                                    </Link>
                                </div>
                                <div className="header-subtitle">{this.state.pageSubtitle}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        );
    };
}

export default Header;
