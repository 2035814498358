import * as React from 'react';
import { Link } from 'react-router-dom';
import { MenuModel } from '../../models/menu.model';
import {MenuLinkModel} from '../../models/menu-link.model';

import * as dsgov from '@govbr/dsgov/dist/dsgov.min';

import './menu.scss';

interface State {
    title?: string;
}

interface Props {
    title?: string;
}

class Menu extends React.Component<Props, State> {

    readonly data: MenuModel = require('../../assets/mock/menu.json');

    readonly govLogo = require('../../assets/img/govbr-logo-large.png');

    readonly mecLogo = require('../../assets/img/logo_mec.png');

    state: State = {};

    init = (): void => {
        this.registerListerners();

        this.initMenu();
    };

    registerListerners = (): void => {
        console.debug('Registrando listeners...');
    };

    componentDidMount = (): void => {
        this.init();
    };

    initMenu = (): void => {
        const menuList = [];

        for (const brMenu of window.document.querySelectorAll('.br-menu')) {
            menuList.push(new dsgov.BRMenu('br-menu', brMenu))
        }
    };

    render = () => {
        return (
            <div className="ac-menu br-menu" id="main-navigation">
                <div className="menu-container">
                    <div className="menu-panel">
                        <div className="menu-header">
                            <div className="menu-title">
                                <img src={this.govLogo} alt="Governo Federal - logo" />
                                <span>Portal Único de Acesso ao Ensino Superior</span>
                            </div>
                            <div className="menu-close">
                                <button className="br-button circle" type="button" aria-label="Fechar o menu" data-dismiss="menu">
                                    <i className="fas fa-times" aria-hidden="true"></i>
                                </button>
                            </div>
                        </div>
                        <nav className="menu-body">
                            { this.data.menu.map((item: MenuLinkModel, index: number) => (
                                item.title !== 'Contato'
                                    ?
                                    <div key={`m-${index}`} className="menu-folder">
                                        <a className="menu-item">
                                        <span className="content">
                                            {item.title}
                                        </span>
                                        </a>
                                        <ul>
                                            { item.children.map((child: MenuLinkModel, index: number) => (
                                                <li key={`mc-${index}`}>
                                                    { !child.isExternal
                                                        ?
                                                        <Link className="menu-item" to={child.uri}>
                                                        <span className="content">
                                                            {child.title}
                                                        </span>
                                                        </Link>
                                                        :
                                                        <a className="menu-item" href={child.uri}>
                                                        <span className="content">
                                                            {child.title}
                                                        </span>
                                                        </a>
                                                    }
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                    :
                                    ''
                            ))}
                        </nav>
                        <div className="menu-footer">
                            { this.data.menu.map((item: MenuLinkModel, index: number) => (
                                item.title === 'Contato'
                                    ?
                                    <div key={`c-${index}`} className="menu-links">
                                        <div className="text-semi-bold mb-3">{item.title}</div>
                                        {item.children.map((child: MenuLinkModel, index: number) => (
                                            <a key={`cc-${index}`} href={child.uri}>
                                                <span className="mr-1">{child.title}</span>
                                            </a>
                                        ))}
                                    </div>
                                    :
                                    ''
                            ))}

                            { this.data.social
                                ?
                                <div className="menu-social">
                                    <div className="text-semi-bold mb-1">Redes Sociais</div>
                                    <div className="sharegroup">
                                        { this.data.social.map((item: MenuLinkModel, index: number) => (
                                            <div key={`s-${index}`} className="share">
                                                <a className="br-button circle" href={item.uri} aria-label={item.title}>
                                                    { item.title === 'Facebook'
                                                        ?
                                                        <i className="fab fa-facebook-f" aria-hidden="true"></i>
                                                        :
                                                        ''
                                                    }
                                                    { item.title === 'Instagram'
                                                        ?
                                                        <i className="fab fa-instagram" aria-hidden="true"></i>
                                                        :
                                                        ''
                                                    }
                                                    { item.title === 'Twitter'
                                                        ?
                                                        <i className="fab fa-twitter" aria-hidden="true"></i>
                                                        :
                                                        ''
                                                    }
                                                    { item.title === 'Youtube'
                                                        ?
                                                        <i className="fab fa-youtube" aria-hidden="true"></i>
                                                        :
                                                        ''
                                                    }
                                                </a>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                                :
                                ''
                            }

                            <div className="menu-info">
                                <div className="text-center text-down-01" dangerouslySetInnerHTML={{__html: this.data.copyright}}>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="menu-scrim" data-dismiss="menu" tabIndex={0}></div>
                </div>
            </div>
        );
    };
}

export default Menu;
