import * as React from 'react';
import Contact from '../../components/contact/contact';
import MockService from '../../services/mock.service';
import ProgramModel from '../../models/program.model';
import ProgramFaqModel from '../../models/program.faq.model';
import {CalendarModel} from '../../models/calendar.model';
import ProgramButtonModel from '../../models/program-button.model';
import GoogleAnalyticsService from '../../services/google-analytics.service';
import {Link} from 'react-router-dom';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from 'react-slick';

import * as dsgov from '@govbr/dsgov/dist/dsgov.min';

import './program.scss';

interface State {
    alias?: string;
    data?: ProgramModel;
    faqData?: ProgramFaqModel;
    avatar?: string;
    avatarMobile?: string;
    speechColor?: string;
    path?: string;
}

interface Props {
    path?: string;
}

class Program extends React.Component<Props, State> {

    readonly sisuMock: ProgramModel = require('../../assets/mock/sisu.json');

    readonly sisuFaqMock: ProgramFaqModel = require('../../assets/mock/sisu-faq.json');

    readonly avatarSisu = require('../../assets/img/sisu_avatar.png');

    readonly avatarSisuMobile = require('../../assets/img/sisu_avatar_mobile.png');

    readonly prouniMock: ProgramModel = require('../../assets/mock/prouni.json');

    readonly prouniFaqMock: ProgramFaqModel = require('../../assets/mock/prouni-faq.json');

    readonly avatarProuni = require('../../assets/img/prouni_avatar.png');

    readonly avatarProuniMobile = require('../../assets/img/prouni_avatar_mobile.png');

    readonly fiesMock: ProgramModel = require('../../assets/mock/fies.json');

    readonly fiesFaqMock: ProgramFaqModel = require('../../assets/mock/fies-faq.json');

    readonly pdfSisu = require('../../assets/download/SISU_documentacao.pdf');

    readonly pdfFies = require('../../assets/download/FIES_documentacao.pdf');

    readonly pdfProuni = require('../../assets/download/PROUNI_documentacao.pdf');

    readonly avatarFies = require('../../assets/img/fies_avatar.png');

    readonly avatarFiesMobile = require('../../assets/img/fies_avatar_mobile.png');

    readonly prevButton = require('../../assets/img/calendar_arrow_left.png');

    readonly nextButton = require('../../assets/img/calendar_arrow_right.png');

    state: State = {};

    alias: string;

    data: ProgramModel = new ProgramModel();

    faqData: ProgramFaqModel = new ProgramFaqModel();

    avatar: string;

    avatarMobile: string;

    speechColor: string;

    mockService: MockService;

    slider: any;

    sliderSettings = {
        arrows: true,
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1600,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 1180,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
                }
            },
            {
                breakpoint: 536,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    arrows: false
                }
            }
        ],
        nextArrow:
            <div id="ac-slider__next" className="ac-slider__next">
                <img height="100" src={this.nextButton} alt="Botão de avançar"/>
            </div>,
        prevArrow:
            <div id="ac-slider__prev" className="ac-slider__prev">
                <img height="100" src={this.prevButton} alt="Botão de voltar"/>
            </div>
    };

    googleAnalyticsService: GoogleAnalyticsService;

    init = (): void => {
        this.mockService = MockService.getInstance();
        this.registerListerners();
        this.scrollToActiveCalendar();
    };

    registerListerners = (): void => {
        const alias = location.pathname.split('/')[1];
        this.alias = alias;
        // const endpoint = `${alias}.json`;
        //
        // this.mockService.get(endpoint)
        //     .then((response: any) => {
        //         if (response && response.data) {
        //             const data: ProgramModel = response.data;
        //             this.data = data;
        //
        //             this.setState({
        //                 data: data,
        //                 alias: alias
        //             });
        //
        //             this.initAccordion();
        //         }
        //     })
        //     .catch((err: any) => {
        //         console.log(err);
        //     });

        let data: ProgramModel;
        let faqData: ProgramFaqModel;

        if (alias === 'sisu') {
            data = this.sisuMock;
            faqData = this.sisuFaqMock;
            this.data = data;
            this.faqData = faqData;
            this.avatar = this.avatarSisu;
            this.avatarMobile = this.avatarSisuMobile;
            this.speechColor = 'ac-oval-speech--red';
        }

        if (alias === 'prouni') {
            data = this.prouniMock;
            faqData = this.prouniFaqMock;
            this.data = data;
            this.faqData = faqData;
            this.avatar = this.avatarProuni;
            this.avatarMobile = this.avatarProuniMobile;
            this.speechColor = 'ac-oval-speech--pink';
        }

        if (alias === 'fies') {
            data = this.fiesMock;
            faqData = this.fiesFaqMock;
            this.data = data;
            this.faqData = faqData;
            this.avatar = this.avatarFies;
            this.avatarMobile = this.avatarFiesMobile;
            this.speechColor = 'ac-oval-speech--blue-4';
        }

        this.setState({
            data: data,
            faqData: faqData,
            alias: alias
        });

        this.initAccordion();
    };

    registerGooglePageView = (): void => {
        this.googleAnalyticsService = GoogleAnalyticsService.getInstance();
        this.googleAnalyticsService.setPageView(this.data.title, window.location.pathname);
    };

    initAccordion = (): void => {
        const accordionList = [];

        for (const brAccordion of window.document.querySelectorAll('.br-accordion')) {
            accordionList.push(
                Object.create(new dsgov.BRAccordion('br-accordion', brAccordion))
            )
        }
    };

    scrollToActiveCalendar = (): void => {
        let indexToScroll = 0;

        const calendar = this.data.calendar;

        for(let i = 0; i < calendar.length; i++) {
            const status = this.getCalendarStatus(calendar[i]);

            if (status === 'finished') {
                indexToScroll = i + 1;
            }

            if (status === 'active') {
                indexToScroll = i;
                break;
            }
        }

        this.slider.slickGoTo(indexToScroll);
    };

    handleCLick = (uri: any): void => {
        window.location = uri;
    };

    download = (): void => {
        if (this.alias === 'sisu') {
            window.open(this.pdfSisu, '_blank');
        }
        if (this.alias === 'fies') {
            window.open(this.pdfFies, '_blank');
        }
        if (this.alias === 'prouni') {
            window.open(this.pdfProuni, '_blank');
        }
    };

    getCalendarStatus = (calendar: CalendarModel) => {
        const now = new Date();
        const startDate = new Date(calendar.startDate);
        const endDate = new Date(calendar.endDate);

        let status = 'waiting';

        if (now >= startDate && now <= endDate) {
            status = 'active';
        }

        if (now > startDate && now > endDate) {
            status = 'finished';
        }

        return status;
    };

    calendarHasLink = (calendar: CalendarModel): boolean => {
        let canClick = false;
        const status = this.getCalendarStatus(calendar);

        if (status === 'active' && calendar.uri) {
            canClick = true;
        }

        return canClick;
    };

    handleCalendarClick = (calendar: CalendarModel) => {
        const status = this.getCalendarStatus(calendar);

        if (status === 'active' && calendar.uri) {
            window.location = calendar.uri as any;
        }
    };

    canDisplayButton = (button: ProgramButtonModel) : boolean => {const now = new Date();
        let canDisplay = false;

        if (button.isForever) {
            canDisplay = true;
        } else {
            const startDate = new Date(button.startDate);
            const endDate = new Date(button.endDate);

            if (now >= startDate && now <= endDate) {
                canDisplay = true;
            }

            if (now > startDate && now > endDate) {
                canDisplay = false;
            }
        }

        return canDisplay;
    };

    componentDidMount = (): void => {
        this.init();
        this.registerGooglePageView();
    };

    componentDidUpdate = (prevProps: Props): void => {
        if (this.props.path !== prevProps.path) {
            this.init();
            this.registerGooglePageView();
        }
    };

    render = () => {
        return (
            <div className="ac-program main-content d-flex flex-column justify-content-between pt-2" id="main-content">
                <div className={`container-lg ac-program__title_container ac-program__title_container--${this.alias}`}>
                    <div className="row">
                        <div className="col-12">
                            <h1>
                                {this.data.title}
                            </h1>
                        </div>
                    </div>
                </div>
                <div className="container-fluid np ac-elevation">
                    <div className="ac-program__calendar-container shadow-sm">
                        <div className="container-lg">
                            <div className="row">
                                <div className="col-12 col-sm-12 col-md-9 order-12 order-sm-12 order-md-0">
                                    <div className="ac-program__calendar">
                                        <div className="ac-program__title">
                                            <strong>Cronograma</strong>
                                        </div>
                                        <div className="ac-slider ac-slider--center ac-slider--program">
                                            <Slider ref={slider => (this.slider = slider)} {...this.sliderSettings}>
                                                {this.data.calendar?.map((item, index) => (
                                                    <div key={`pc-${index}`} className="ac-slider__content">
                                                        <div className={`ac-calendar ac-calendar--${this.getCalendarStatus(item)} ${this.calendarHasLink(item) ? 'ac-calendar--pointer' : ''}`} onClick={() => this.handleCalendarClick(item)}>
                                                            <div className="ac-calendar__status">
                                                                {this.getCalendarStatus(item) == 'waiting' ? 'Não iniciado': ''}
                                                                {this.getCalendarStatus(item) == 'active' ? 'Em processo': ''}
                                                                {this.getCalendarStatus(item) == 'finished' ? 'Encerrado': ''}
                                                            </div>
                                                            <div className="ac-calendar__text">
                                                                <div dangerouslySetInnerHTML={{__html: item.text}}></div>

                                                                { item.buttonText && this.getCalendarStatus(item) == 'active'
                                                                    ?
                                                                    <button className='br-button primary' aria-label={item.buttonText}>
                                                                        {item.buttonText}
                                                                    </button>
                                                                    :
                                                                    ''
                                                                }
                                                            </div>
                                                            { item.textDate
                                                                ?
                                                                <div className="ac-calendar__date">
                                                                    <i className="fas fa-calendar-alt"></i>
                                                                    <span>{item.textDate}</span>
                                                                </div>
                                                                :
                                                                ''
                                                            }
                                                        </div>
                                                    </div>
                                                ))}
                                            </Slider>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-sm-12 col-md-3 relative">
                                    <div className={`ac-program__speech ac-program__speech--${this.alias}`}>
                                        <blockquote className={`ac-oval-speech ac-oval-speech--rounded ac-oval-speech--right ac-oval-speech--medium-alt ${this.speechColor} d-none d-sm-none d-md-block d-lg-block d-xl-block`}>
                                            <p dangerouslySetInnerHTML={{__html: this.data.avatar?.text}}></p>
                                        </blockquote>
                                        <blockquote className={`ac-oval-speech ac-oval-speech--rounded ac-oval-speech--bottom ac-oval-speech--medium-alt ${this.speechColor} d-block d-sm-block d-md-none d-lg-none d-xl-none`}>
                                            <p dangerouslySetInnerHTML={{__html: this.data.avatar?.text}}></p>
                                        </blockquote>
                                    </div>
                                    <div className="ac-program__avatar-desktop d-none d-sm-none d-md-block d-lg-block d-xl-block">
                                        <img height="373" src={this.avatar} alt="Avatar"/>
                                    </div>
                                    <div className="ac-program__avatar-mobile d-block d-sm-block d-md-none d-lg-none d-xl-none">
                                        <img height="170" src={this.avatarMobile} alt="Avatar"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container-lg">
                    <div className="row">
                        <div className="col-12 col-sm-12 col-md-7 col-lg-8 mt-6">
                            { this.data.content?.map((item, index) => (
                                <div key={`pc-${index}`} className="ac-program__content">
                                    <div className="ac-program__title">
                                        <strong>{item.title}</strong>
                                    </div>
                                    <div className="ac-program__text">
                                        <p dangerouslySetInnerHTML={{__html: item.text}}></p>
                                    </div>
                                </div>
                            ))}

                            { this.data.topics && this.data.topics.length > 0
                                ?
                                <div className="br-accordion mt-6" id="accordion1">
                                    {this.data.topics.map((item, index) => (
                                        <>
                                            <div className="item">
                                                <button className="header" type="button" aria-controls={ `id-${index}` }>
                                                    <span className="title">{item.title}</span>
                                                    <span className="icon">
                                                            <i className="fas fa-angle-down" aria-hidden="true"></i>
                                                        </span>
                                                </button>
                                            </div>
                                            <div className="content" id={ `id-${index}` }>
                                                <p dangerouslySetInnerHTML={{__html: item.text}}></p>
                                            </div>
                                        </>
                                    ))}
                                </div>
                                :
                                ''
                            }

                            { this.data.bottomAlert
                                ?
                                <p className="ac-program__bottom-alert">
                                    {this.data.bottomAlert}
                                </p>
                                :
                                ''
                            }

                            { this.data.buttons && this.data.buttons.length > 0
                                ?
                                <div className="ac-program__buttons-container">
                                    {this.data.buttons.map((item, index) => (
                                        <button key={`b-${index}`} className={`br-button primary ac-program__button ${!this.canDisplayButton(item) ? 'ac-program__button--hide' : ''}`} type="button" aria-label={item.text} onClick={() => this.handleCLick(item.uri)}>
                                            {item.text}
                                        </button>
                                    ))}
                                </div>
                                :
                                ''
                            }
                        </div>
                        <div className="col-12 col-sm-12 col-md-5 col-lg-4 mt-6">
                            { 
                            
                            (this.data.videos && this.data.videos.length > 0) && process.env.HIDE_ELECTORAL_CONTENT === 'false'
                                ?
                                this.data.videos.map((item, index) => (
                                    <div key={`pv-${index}`} className="ac-program__video">
                                        <iframe key={`v-${index}`}
                                                src={item.uri}
                                                title={item.text}
                                                frameBorder="0"
                                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                allowFullScreen></iframe>
                                    </div>
                                ))
                                :
                                ''
                            }
                            <div className="ac-program__download">
                                <button className="br-button primary" type="button" aria-label={this.data.download?.text} onClick={() => this.download()}>
                                    <span>{this.data.download?.text}</span>
                                    <i className="fas fa-file-download"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container-fluid np ac-elevation mt-6">
                    <div className="ac-program__faq shadow-sm-up">
                        <div className="row">
                            <div className="col">
                                <div className="container-lg pt-6 pb-6">
                                    <div className="text-center">
                                        <span className="ac-program__faq-title">Tire suas dúvidas sobre o <span className="text-uppercase">{this.alias}</span></span>
                                    </div>
                                    <div className="text-center mt-3 ac-program__faq-links">
                                        {this.faqData.faqGroups?.map((item, index) => (
                                            <Link key={`fb-${index}`} className="br-button secondary ac-program--button" type="button" aria-label={item.title} to={`/${this.alias}/duvidas#${item.id}`}>
                                                <span>{item.title}</span>
                                            </Link>
                                        ))}
                                    </div>
                                    <div className="ac-program__faq-all mt-4 text-center">
                                        <Link to={`/${this.alias}/duvidas`}>
                                            <strong>Ver todas as dúvidas</strong>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Contact alias={this.alias} />
                </div>
            </div>
        );
    };
}

export default Program;
