import * as React from 'react';
import Questions from '../../components/questions/questions';
import Contact from '../../components/contact/contact';
import { HomeModel } from '../../models/home.model';
import ProgramModel from '../../models/program.model';
import { CalendarModel } from '../../models/calendar.model';
import GoogleAnalyticsService from '../../services/google-analytics.service';
import { Link } from "react-router-dom";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from 'react-slick';

import './home.scss';

interface State {
    sisuCalendar?: Array<CalendarModel>;
    prouniCalendar?: Array<CalendarModel>;
    fiesCalendar?: Array<CalendarModel>;
    path?: string;
}

interface Props {
    path?: string;
}

class Home extends React.Component<Props, State> {

    readonly data: HomeModel = require('../../assets/mock/home.json');

    readonly logo = require('../../assets/img/logo.svg');

    readonly avatar = require('../../assets/img/home_avatar.png');

    readonly avatarMobile = require('../../assets/img/home_avatar_mobile.png');

    readonly sisuLogo = require('../../assets/img/logo_sisu.svg');

    readonly sisuMock: ProgramModel = require('../../assets/mock/sisu.json');

    readonly prouniLogo = require('../../assets/img/logo_prouni.svg');

    readonly prouniMock: ProgramModel = require('../../assets/mock/prouni.json');

    readonly fiesLogo = require('../../assets/img/logo_fies.svg');

    readonly fiesMock: ProgramModel = require('../../assets/mock/fies.json');

    readonly buscaIcon = require('../../assets/img/home_busca.png');

    readonly acessoIcon = require('../../assets/img/home_acesso_4.png');

    sisuCalendar: Array<CalendarModel> = new Array<CalendarModel>();

    prouniCalendar: Array<CalendarModel> = new Array<CalendarModel>();

    fiesCalendar: Array<CalendarModel> = new Array<CalendarModel>();

    state: State = {};

    sliderSettings = {
        arrows: true,
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
        nextArrow:
            <div id="ac-slider__next" className="ac-slider__next">
                <i className="fas fa-chevron-right"></i>
            </div>,
        prevArrow:
            <div id="ac-slider__prev" className="ac-slider__prev">
                <i className="fas fa-chevron-left"></i>
            </div>,
        responsive: [
            {
                breakpoint: 991,
                settings: {
                    arrows: false
                }
            }
        ],
    };

    googleAnalyticsService: GoogleAnalyticsService;

    init = (): void => {
        this.registerListerners();
        this.setCalendars();
    };

    registerListerners = (): void => {
        console.debug('Registrando listeners...');
    };

    registerGooglePageView = (): void => {
        this.googleAnalyticsService = GoogleAnalyticsService.getInstance();
        this.googleAnalyticsService.setPageView(this.data.title, window.location.pathname);
    };

    setCalendars = (): void => {
        const now = new Date();

        this.sisuCalendar = [];
        this.prouniCalendar = [];
        this.fiesCalendar = [];

        if (this.sisuMock.calendar) {
            for (let i = 0; i < this.sisuMock.calendar.length; i++) {
                const startDate = new Date(this.sisuMock.calendar[i].startDate);
                const endDate = new Date(this.sisuMock.calendar[i].endDate);

                if (now >= startDate && now <= endDate) {
                    this.sisuCalendar.push(this.sisuMock.calendar[i]);
                }
            }
        }

        if (this.prouniMock.calendar) {
            for (let i = 0; i < this.prouniMock.calendar.length; i++) {
                const startDate = new Date(this.prouniMock.calendar[i].startDate);
                const endDate = new Date(this.prouniMock.calendar[i].endDate);

                if (now >= startDate && now <= endDate) {
                    this.prouniCalendar.push(this.prouniMock.calendar[i]);
                }
            }
        }

        if (this.fiesMock.calendar) {
            for (let i = 0; i < this.fiesMock.calendar.length; i++) {
                const startDate = new Date(this.fiesMock.calendar[i].startDate);
                const endDate = new Date(this.fiesMock.calendar[i].endDate);

                if (now >= startDate && now <= endDate) {
                    this.fiesCalendar.push(this.fiesMock.calendar[i]);
                }
            }
        }

        this.setState({
            sisuCalendar: this.sisuCalendar,
            prouniCalendar: this.prouniCalendar,
            fiesCalendar: this.fiesCalendar
        });
    };

    getCalendarStatus = (calendar: CalendarModel) => {
        const now = new Date();
        const startDate = new Date(calendar.startDate);
        const endDate = new Date(calendar.endDate);

        let status = 'waiting';

        if (now >= startDate && now <= endDate) {
            status = 'active';
        }

        if (now > startDate && now > endDate) {
            status = 'finished';
        }

        return status;
    };

    calendarHasLink = (calendar: CalendarModel): boolean => {
        let canClick = false;
        const status = this.getCalendarStatus(calendar);

        if (status === 'active' && calendar.uri) {
            canClick = true;
        }

        return canClick;
    };

    handleCalendarClick = (calendar: CalendarModel) => {
        const status = this.getCalendarStatus(calendar);

        if (status === 'active' && calendar.uri) {
            window.location = calendar.uri as any;
        }
    };

    componentDidMount = (): void => {
        this.init();
        this.registerGooglePageView();
    };

    componentDidUpdate = (prevProps: Props): void => {
        if (this.props.path !== prevProps.path) {
            this.init();
        }
    };

    checkCalendarContent = (): boolean => {
        return this.sisuCalendar && this.sisuCalendar.length > 0 || this.prouniCalendar && this.prouniCalendar.length > 0 || this.fiesCalendar && this.fiesCalendar.length > 0;
    };

    render = () => {
        return (
            <div className="ac-home main-content d-flex flex-column justify-content-between" id="main-content">
                <div className="container-fluid np">
                    <div className="ac-home__top">
                        <div className="container-lg">
                            <div className="row">
                                <div className="col-12 col-sm-12 col-md-6 col-lg-7">
                                        {/* <Link className="ac-home__text" to="/">
                                            Acesso Único
                                        </Link> : */}
                                        <Link className="ac-home__logo" to="/">
                                            <img className="ac-contrast" src={this.logo} alt="Acesso Único - Logo" />
                                        </Link>
                                       
                                </div>
                                <div className="col-12 col-sm-12 col-md-6 col-lg-5 d-none d-sm-none d-md-block">
                                    <div className="ac-home__programs-top">
                                        <Link to="/sisu">
                                            <img className="ac-contrast" src={this.sisuLogo} alt="SISU" />
                                        </Link>
                                        <Link to="/prouni">
                                            <img className="ac-contrast" src={this.prouniLogo} alt="PROUNI" />
                                        </Link>
                                        <Link to="/fies">
                                            <img className="ac-contrast" src={this.fiesLogo} alt="FIES" />
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container-lg pt-6 pb-6">
                    <div className="row">
                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-7 d-flex flex-column">
                            <h1 className="pr-6">
                                {this.data.title}
                            </h1>
                            <div className="ac-home__welcome-speech">
                                <div className="ac-home__top-speech">
                                    <div className="mb-4 ac-contrast">
                                        <p className="ac-contrast" dangerouslySetInnerHTML={{ __html: this.data.welcomeText }}></p>
                                    </div>
                                    <blockquote
                                        className="ac-oval-speech ac-oval-speech--blue-3 ac-oval-speech--rounded ac-oval-speech--top-left"
                                        dangerouslySetInnerHTML={{ __html: this.data.balloonTop.text }}>
                                    </blockquote>
                                </div>
                                <div className="ac-home__right-speech">
                                    <div className="relative">
                                        <blockquote
                                            className="ac-oval-speech ac-oval-speech--white ac-oval-speech--left-2 ac-oval-speech--rounded ac-oval-speech--medium-3">
                                            <div className="ac-oval-speech--white-triangle"></div>
                                            <div dangerouslySetInnerHTML={{ __html: this.data.balloonRight.text }}></div>
                                            <Link className="br-button primary" type="button"
                                                aria-label={this.data.balloonRight.action.title}
                                                to={this.data.balloonRight.action.uri}>
                                                {this.data.balloonRight.action.title}
                                            </Link>
                                        </blockquote>
                                        <blockquote
                                            className="ac-oval-speech ac-oval-speech--white ac-oval-speech--left-2 ac-oval-speech--rounded ac-oval-speech--medium-3">
                                            <div className="ac-oval-speech--white-triangle"></div>
                                            <div dangerouslySetInnerHTML={{ __html: this.data.balloonRightBottom.text }}></div>
                                            <Link className="br-button primary" type="button"
                                                aria-label={this.data.balloonRightBottom.action.title}
                                                to={this.data.balloonRightBottom.action.uri}>
                                                {this.data.balloonRightBottom.action.title}
                                            </Link>
                                        </blockquote>
                                    </div>
                                </div>
                                <img className="ac-home__avatar" src={this.avatar} alt="" />
                            </div>
                            <div className="ac-home__welcome-mobile-speech">
                                <div className="ac-home__top-speech">
                                    <div className="mb-4">
                                        <p className="ac-contrast" dangerouslySetInnerHTML={{ __html: this.data.welcomeText }}></p>
                                    </div>
                                    <blockquote
                                        className="ac-oval-speech ac-oval-speech--rounded ac-oval-speech--bottom ac-oval-speech--100 mb-3"
                                        dangerouslySetInnerHTML={{ __html: this.data.balloonTop.text }}>
                                    </blockquote>
                                </div>
                                <img className="ac-home__avatar-mobile" src={this.avatarMobile} alt="" />

                                <div className="ac-home__bottom-speech mt-6">
                                    <blockquote
                                        className="ac-oval-speech ac-oval-speech--white ac-oval-speech--rounded ac-oval-speech--top-2 ac-oval-speech--100">
                                        <div className="ac-oval-speech--white-triangle"></div>
                                        <div dangerouslySetInnerHTML={{ __html: this.data.balloonRight.text }}></div>
                                        <Link className="br-button primary" type="button"
                                            aria-label={this.data.balloonRight.action.title}
                                            to={this.data.balloonRight.action.uri}>
                                            {this.data.balloonRight.action.title}
                                        </Link>
                                    </blockquote>

                                    <blockquote
                                        className="ac-oval-speech ac-oval-speech--white ac-oval-speech--rounded ac-oval-speech--top-2 ac-oval-speech--100 mt-6">
                                        <div className="ac-oval-speech--white-triangle"></div>
                                        <div dangerouslySetInnerHTML={{ __html: this.data.balloonRightBottom.text }}></div>
                                        <Link className="br-button primary" type="button"
                                            aria-label={this.data.balloonRightBottom.action.title}
                                            to={this.data.balloonRightBottom.action.uri}>
                                            {this.data.balloonRightBottom.action.title}
                                        </Link>
                                    </blockquote>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-5">
                            <div className="row">
                                <div className="col-12 text-center d-block d-sm-block d-md-none d-lg-none d-xl-none">
                                    <p className="ac-home__highlight-title ac-home__highlight-title--programs ac-contrast pt-6 pb-6">
                                        Saiba mais sobre
                                    </p>
                                </div>
                                <div className="col-12">
                                    <div className="ac-home__programs d-block d-sm-block d-md-none d-lg-none d-xl-none">
                                        <Link to="/sisu">
                                            <img className="ac-contrast" src={this.sisuLogo} alt="SISU" />
                                        </Link>
                                        <Link to="/prouni">
                                            <img className="ac-contrast" src={this.prouniLogo} alt="PROUNI" />
                                        </Link>
                                        <Link to="/fies">
                                            <img className="ac-contrast" src={this.fiesLogo} alt="FIES" />
                                        </Link>
                                    </div>
                                </div>
                                <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                                    <div className="ac-card ac-card--secondary-blue br-card">
                                        <div className="card-header">
                                            {this.data.cards[0].title}
                                        </div>
                                        <div className="card-content">
                                            <img src={this.buscaIcon} alt="Busca avançada - ícone" />
                                            <p className="ac-contrast"
                                                dangerouslySetInnerHTML={{ __html: this.data.cards[0].text }}>
                                            </p>
                                        </div>
                                        <div className="card-footer">
                                            <Link className="br-button primary" type="button"
                                                aria-label={this.data.cards[0].action.title}
                                                to={this.data.cards[0].action.uri}>
                                                {this.data.cards[0].action.title}
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                                    <div className="ac-card ac-card--gray br-card">
                                        <div className="card-header">
                                            {this.data.cards[1].title}
                                        </div>
                                        <div className="card-content">
                                            <img src={this.acessoIcon} alt="Sobre o projeto - ícone" />
                                            <p className="ac-contrast"
                                                dangerouslySetInnerHTML={{ __html: this.data.cards[1].text }}>
                                            </p>
                                        </div>
                                        <div className="card-footer">
                                            <Link className="br-button primary" type="button"
                                                aria-label={this.data.cards[1].action.title}
                                                to={this.data.cards[1].action.uri}>
                                                {this.data.cards[1].action.title}
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 mt-3 relative">


                                    <p className="ac-home__highlight-title ac-contrast">
                                        {this.checkCalendarContent() && "Destaques"}
                                    </p>

                                    <div className="ac-slider ac-slider--center ac-slider--home">
                                        <Slider {...this.sliderSettings}>
                                            {this.sisuCalendar.map((item: CalendarModel, index: number) => (
                                                <div key={`ahs-${index}`} className="ac-slider__content">
                                                    <span>
                                                        <strong>Cronograma SISU / 2024</strong>
                                                    </span>

                                                    <div
                                                        className={`ac-calendar ac-calendar--active ${this.calendarHasLink(item) ? 'ac-calendar--pointer' : ''}`}
                                                        onClick={() => this.handleCalendarClick(item)}>
                                                        <div className="ac-calendar__status">
                                                            Em processo
                                                        </div>
                                                        <div className="ac-calendar__text">
                                                            <div dangerouslySetInnerHTML={{ __html: item.text }}></div>

                                                            {item.buttonText
                                                                ?
                                                                <button className='br-button primary' aria-label={item.buttonText}>
                                                                    {item.buttonText}
                                                                </button>
                                                                :
                                                                ''
                                                            }
                                                        </div>
                                                        <div className="ac-calendar__date">
                                                            <i className="fas fa-calendar-alt"></i>
                                                            <span>{item.textDate}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}

                                            {this.prouniCalendar.map((item: CalendarModel, index: number) => (
                                                <div key={`ahp-${index}`} className="ac-slider__content">
                                                    <span>
                                                        <strong>Cronograma PROUNI / 2024</strong>
                                                    </span>

                                                    <div
                                                        className={`ac-calendar ac-calendar--active ${this.calendarHasLink(item) ? 'ac-calendar--pointer' : ''}`}
                                                        onClick={() => this.handleCalendarClick(item)}>
                                                        <div className="ac-calendar__status">
                                                            Em processo
                                                        </div>
                                                        <div className="ac-calendar__text">
                                                            <div dangerouslySetInnerHTML={{ __html: item.text }}></div>

                                                            {item.buttonText
                                                                ?
                                                                <button className='br-button primary' aria-label={item.buttonText}>
                                                                    {item.buttonText}
                                                                </button>
                                                                :
                                                                ''
                                                            }
                                                        </div>
                                                        <div className="ac-calendar__date">
                                                            <i className="fas fa-calendar-alt"></i>
                                                            <span>{item.textDate}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}

                                            {this.fiesCalendar.map((item: CalendarModel, index: number) => (
                                                <div key={`ahf--${index}`} className="ac-slider__content">
                                                    <span>
                                                        <strong>Cronograma FIES / 2024</strong>
                                                    </span>

                                                    <div
                                                        className={`ac-calendar ac-calendar--active ${this.calendarHasLink(item) ? 'ac-calendar--pointer' : ''}`}
                                                        onClick={() => this.handleCalendarClick(item)}>
                                                        <div className="ac-calendar__status">
                                                            Em processo
                                                        </div>
                                                        <div className="ac-calendar__text">
                                                            <div dangerouslySetInnerHTML={{ __html: item.text }}></div>

                                                            {item.buttonText
                                                                ?
                                                                <button className='br-button primary' aria-label={item.buttonText}>
                                                                    {item.buttonText}
                                                                </button>
                                                                :
                                                                ''
                                                            }
                                                        </div>
                                                        <div className="ac-calendar__date">
                                                            <i className="fas fa-calendar-alt"></i>
                                                            <span>{item.textDate}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}

                                            {!process.env.HIDE_ELECTORAL_CONTENT || process.env.HIDE_ELECTORAL_CONTENT === 'false'
                                                ?
                                                this.data.carrossel?.map((item, index) => (
                                                    <div key={`hc-${index}`}
                                                        className="ac-slider__content ac-slider__content--no-padding">
                                                        <iframe key={`v-${index}`}
                                                            src={item.uri}
                                                            title={item.text}
                                                            frameBorder="0"
                                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                            allowFullScreen></iframe>
                                                    </div>
                                                ))
                                                :
                                                ''
                                            }
                                        </Slider>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container-fluid np ac-elevation">
                    <Questions />
                    <Contact />
                </div>
            </div>
        );
    };
}

export default Home;
