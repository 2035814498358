import * as React from 'react';
import {ContactModel} from '../../models/contact.model';

import './contact.scss';

interface State {
    title: string
}

interface Props {
    alias?: string
}

class Contact extends React.Component<Props, State> {

    readonly data: ContactModel = require('../../assets/mock/contact.json');

    readonly sisuLogo = require('../../assets/img/logo_sisu.svg');

    readonly prouniLogo = require('../../assets/img/logo_prouni.svg');

    readonly fiesLogo = require('../../assets/img/logo_fies.svg');

    init = (): void => {
        this.registerListerners();
    };

    registerListerners = (): void => {
        console.debug('Registrando listeners...');
    };

    handleCLick = (uri: any): void => {
        window.location = uri;
    };

    componentDidMount = (): void => {
        this.init();
    };

    render = () => {
        return (
            <div className="ac-contact shadow-sm-up">
                <div className="container-lg">
                    <div className="row">
                        <div className="col-12 text-center pt-6 pb-6 ac-contact__container">
                            <div className="ac-contact__info">
                                <div>
                                    <p className="ac-contrast">{this.data.firstText}</p>
                                    <button className="br-button primary mt-3 mb-3" type="button"
                                            aria-label={this.data.action.title}
                                            onClick={() => this.handleCLick(this.data.action.uri)}>
                                        {this.data.action.title}
                                    </button>
                                    <p className="ac-contrast">{this.data.secondText}</p>
                                    <p className="ac-contrast">
                                        <a href={`tel:${this.data.phone}`}>
                                            <strong>{this.data.phone}</strong>
                                        </a>
                                    </p>
                                </div>
                            </div>

                            {this.props.alias
                                ?
                                <div className="ac-contact__program">
                                    {this.props.alias === 'sisu'
                                        ?
                                        <img height="100" className="ac-contrast" src={this.sisuLogo} alt="Sisu"/>
                                        :
                                        ''
                                    }
                                    {this.props.alias === 'prouni'
                                        ?
                                        <img height="100" className="ac-contrast" src={this.prouniLogo} alt="Prouni"/>
                                        :
                                        ''
                                    }
                                    {this.props.alias === 'fies'
                                        ?
                                        <img height="100" className="ac-contrast" src={this.fiesLogo} alt="Fies"/>
                                        :
                                        ''
                                    }
                                </div>
                                :
                                ''
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    };
}

export default Contact;
